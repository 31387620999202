import React from "react";
import CancelRoundBlue from "../../resources/small-x-button-round-blue.svg";

function LabeledTextboxInput(props) {
  const onChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (props.numbersOnly) {
      if (e.target.value === "" || re.test(e.target.value)) {
        props.setValue(e.target.value);
      }
    } else {
      props.setValue(e.target.value);
    }
  };

  let loadingPlacehodler = (
    <div
      className={`w-full rounded-md h-[42px] py-1.5 px-2.5 relative ui-placeholder`}
    ></div>
  );

  return (
    <div className={`w-full text-snow-primary mb-5 `}>
      <div className={`text-sm font-bold mb-0.5 `}>{props.label}</div>
      {props.description ? (
        <div className="text-sm text-snow-primary font-light mb-1">
          {props.description}
        </div>
      ) : null}
      {props.loading ? (
        loadingPlacehodler
      ) : (
        <div
          className={`w-full rounded-md border ${props.disabled ? "border-snow-extra-light-grey" : "border-snow-primary"} py-1.5 px-2.5 relative`}
        >
          {props.disabled ? (
            <div className="w-full text-lg font-regular border-none bg-transparent">
              {props.value}
            </div>
          ) : props.multiline ? (
            <textarea
              className="w-full text-lg font-regular border-none bg-transparent outline-none"
              maxLength={props.maxLength}
              onChange={onChange}
              placeholder={props.placeholder}
              value={props.value}
              rows={3}
            />
          ) : (
            <input
              className="w-full text-lg font-regular border-none bg-transparent outline-none invalid:text-snow-red"
              maxLength={props.maxLength}
              onChange={onChange}
              placeholder={props.placeholder}
              type={props.type ?? "text"}
              value={props.value}
              data-testid={props.testId ?? `input-${props.label}`}
            />
          )}
          {props.showCancelTopRight ? (
            <div
              className="absolute top-0 right-0 translate-x-2 -translate-y-2 hover:opacity-60 active:opacity-90"
              onClick={(e) => {
                props.cancelClicked();
                // Don't run card pressed function
                e.stopPropagation();
              }}
            >
              <img className="w-4" src={CancelRoundBlue} alt="cancel" />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}

export default LabeledTextboxInput;
