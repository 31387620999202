import React from "react";
const LabeledField = (props) => {
  return (
    <div className="w-full text-snow-primary mb-5">
      <div className="text-sm font-bold mb-0.5">{props.label}</div>
      {props.children}
    </div>
  );
};
export default LabeledField;
