import React from "react";

const FeaturesCard = (props) => {
  return (
    <div
      className={`bg-gradient-to-b rounded-xl py-10 px-4 relative flex-1 flex flex-col justify-between ${
        props.featured
          ? "border-2 border-landing-page-gray-secondary from-landing-page-black to-landing-page-pricing-card-gradient-start"
          : "from-landing-page-pricing-card-gradient-start to-landing-page-black"
      }`}
    >
      <div className="flex flex-col">
        <div className="bg-landing-page-gray-secondary rounded-xl w-24 h-24 flex items-center justify-center">
          <img src={props.icon} alt={props.title} className="w-10 h-10" />
        </div>
        <div className="font-catamaran text-3xl text-snow-white mt-10">
          {props.title}
        </div>
        <div className="ml-4">
          <ul className="list-disc pl-2.5">
            {props.details.map((detail, index) => (
              <li
                key={index}
                className="text-snow-white text-lg font-light mt-4"
              >
                {detail}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FeaturesCard;
