import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// Components
import StandardButton from "../../../components/Buttons/StandardButton";
import CompanyServiceCard from "../../../components/Cards/CompanyServiceCard";
import HeaderBar from "../../../components/Header/HeaderBar";
import StandardLabel from "../../../components/Labels/StandardLabel";
import LoadingIcon from "../../../components/Loaders/LoadingIcon";
import CreateServiceModal from "../../../components/Modals/CreateServiceModal";

// Services
import axiosCompanies from "../../../services/axios/companies";

function sortAtoZ(array, field) {
  const sorted = array.sort((a, b) => {
    let aValue = a[field].toLowerCase();
    let bValue = b[field].toLowerCase();
    if (aValue < bValue) {
      return -1;
    }
    if (aValue > bValue) {
      return 1;
    }
    return 0;
  });
  return sorted;
}

const AdminUpdateCompanyServicesPage = (props) => {
  const [companyServices, setCompanyServices] = useState({});
  const [loading, setLoading] = useState(true);
  const [displayCreateServiceModal, setDisplayCreateServiceModal] =
    useState(false);
  const [displayUpdateServiceModal, setDisplayUpdateServiceModal] =
    useState(false);
  const [serviceBeingUpdated, setServiceBeingUpdated] = useState(null);

  useEffect(() => {
    setLoading(true);
    axiosCompanies.getCommonServices(
      { companyId: props.currentUser.currentCompanyId },
      (data) => {
        setCompanyServices(data);

        setLoading(false);
      },
    );
  }, [props.currentUser.currentCompanyId]);

  // Helper camelcase handler
  const toCamelCase = (str) => {
    return str
      .replace(/\s+/g, "") // Remove spaces
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) => {
        return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
      })
      .replace(/[-_]/g, ""); // Remove hyphens or underscores
  };

  const handleCreateNewService = (newService, season) => {
    axiosCompanies.addCommonService(
      {
        companyId: props.currentUser.currentCompanyId,
        newService: {
          ...newService,
          key: toCamelCase(newService.label), // Create camelcase key
        },
        season,
      },
      (data) => {
        setCompanyServices(data.commonCompanyServices);
        setLoading(false);
      },
      (err) => {
        console.log(
          "Failed to create new service. Please try again. Error: ",
          err,
        );
        alert("Failed to create new service. Please try again.");
      },
    );

    setDisplayCreateServiceModal(false);
  };

  const handleOpenUpdateServiceModal = (service) => {
    setServiceBeingUpdated(service);
    setDisplayUpdateServiceModal(true);
  };

  const handleUpdateService = (updatedService) => {
    const updatedCommonServices = { ...companyServices };

    updatedCommonServices[serviceBeingUpdated.season][
      serviceBeingUpdated.index
    ] = {
      ...updatedService,
      checked: false,
    };

    axiosCompanies.updateCommonServices(
      {
        commonCompanyServices: updatedCommonServices,
        companyId: props.currentUser.currentCompanyId,
      },
      (data) => {
        setCompanyServices(data.commonCompanyServices);

        setLoading(false);
      },
      (err) => {
        console.log("Failed to update service. Please try again. Error: ", err);
        alert("Failed to update service. Please try again.");
      },
    );

    setServiceBeingUpdated(null);
    setDisplayUpdateServiceModal(false);
  };

  const handleDeleteService = () => {
    // Make copies of all services and selected season
    const updatedCommonServices = { ...companyServices };
    const updatedSeasonOfServices = [
      ...companyServices[serviceBeingUpdated.season],
    ];

    // Delete single item from array
    updatedSeasonOfServices.splice(serviceBeingUpdated.index, 1);

    // Update complete service object
    updatedCommonServices[serviceBeingUpdated.season] = updatedSeasonOfServices;

    axiosCompanies.updateCommonServices(
      {
        commonCompanyServices: updatedCommonServices,
        companyId: props.currentUser.currentCompanyId,
      },
      (data) => {
        setCompanyServices(data.commonCompanyServices);

        setLoading(false);
      },
      (err) => {
        console.log("Failed to delete service. Please try again. Error: ", err);
        alert("Failed to delete service. Please try again.");
      },
    );

    setServiceBeingUpdated(null);
    setDisplayUpdateServiceModal(false);
  };

  return (
    <div className="flex flex-col items-center">
      <HeaderBar title="Services" />
      {loading ? (
        <LoadingIcon />
      ) : (
        <div className="flex flex-1 flex-col w-full p-10 items-center overflow-y-scroll">
          <div className="flex flex-row w-full justify-end">
            <StandardButton
              color="blue"
              label="Add New Service"
              onClick={() => setDisplayCreateServiceModal(true)}
            />
          </div>
          <div className="w-full">
            <div className="mt-6">
              <StandardLabel label="Snow" />
              {sortAtoZ(companyServices.snow, "label").map((service, index) => (
                <CompanyServiceCard
                  key={index}
                  label={service.label}
                  onClick={() => {
                    handleOpenUpdateServiceModal({
                      ...service,
                      index: index,
                      season: "snow",
                    });
                  }}
                  showEdit={true}
                />
              ))}
            </div>
            <div className="mt-10">
              <StandardLabel label="Landscape" />
              {sortAtoZ(companyServices.landscape, "label").map(
                (service, index) => (
                  <CompanyServiceCard
                    key={index}
                    label={service.label}
                    onClick={() => {
                      handleOpenUpdateServiceModal({
                        ...service,
                        index,
                        season: "landscape",
                      });
                    }}
                    showEdit={true}
                  />
                ),
              )}
            </div>
          </div>
        </div>
      )}
      {/* Create Service Modal */}
      <CreateServiceModal
        adjustForSidebar
        chooseSeason={true}
        onClose={() => setDisplayCreateServiceModal(false)}
        onCreateService={handleCreateNewService}
        open={displayCreateServiceModal}
      />
      {/* Update Service Modal */}
      <CreateServiceModal
        adjustForSidebar
        chooseSeason={false}
        enableDelete={true}
        initialServiceValue={serviceBeingUpdated}
        onClose={() => setDisplayUpdateServiceModal(false)}
        onCreateService={handleUpdateService}
        onDelete={handleDeleteService}
        open={displayUpdateServiceModal}
        title="Update Service"
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(AdminUpdateCompanyServicesPage);
