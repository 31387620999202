import React, { useState } from "react";
import StandardButton from "../Buttons/StandardButton";
import DropDownMenu from "./DropDownMenu";

const HeaderBar = (props) => {
  const [buttonLoadingMap, setButtonLoadingMap] = useState({});

  let overflowOptions = [];
  if (props.buttons && props.buttons.length > 1) {
    overflowOptions = props.buttons.map((b) => ({
      label: b.label,
      operation: b.onClick,
      color: b.color || "blue",
    }));
    overflowOptions.pop();
  }
  return (
    <div className="flex flex-row place-items-center justify-between w-full h-20 border-b-2 border-snow-primary px-2 md:px-10">
      <div className="text-3xl font-medium text-snow-primary">
        {props.title}
      </div>
      <div className="flex justify-end">
        {props.button?.enabled ? (
          <StandardButton
            color={props.button.color || "blue"}
            disabled={props.button.disabled || false}
            label={props.button.label}
            onClick={props.button.onClick}
          />
        ) : null}
        {props.buttons?.map((item, key) => (
          <div
            className={`ml-4 ${key === props.buttons.length - 1 ? "" : "hidden"} lg:block print:hidden`}
            key={key}
          >
            <StandardButton
              color={item.color || "blue"}
              disabled={item.disabled || false}
              label={item.label}
              loading={item.loading || buttonLoadingMap[key]}
              onClick={async () => {
                setButtonLoadingMap((current) => ({
                  ...current,
                  [key]: true,
                }));
                await item.onClick();
                setButtonLoadingMap((current) => ({
                  ...current,
                  [key]: false,
                }));
              }}
            />
          </div>
        ))}
        {props.buttons && props.buttons.length > 1 ? (
          <DropDownMenu className="lg:hidden mx-4" options={overflowOptions} />
        ) : null}
      </div>

      {props.dropdown?.enabled ? (
        <DropDownMenu options={props.dropdown.options} />
      ) : null}
    </div>
  );
};

export default HeaderBar;
