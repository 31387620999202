import React from "react";
import { useNavigate } from "react-router-dom";

// Components
import LandingPageGradientButton from "./components/LandingPageGradientButton";
import WithSnowFlakes from "../../components/LandingPage/WithSnowFlakes";
import SnowRemovalImage from "../../resources/landing-page/snow-removal.jpg";

const AboutPage = () => {
  const navigate = useNavigate();

  return (
    <WithSnowFlakes>
      <div className="flex flex-col w-full items-center px-4 py-20 overflow-scroll ">
        <div className="w-full max-w-[1200px]">
          <div className="text-snow-white font-bold text-5xl text-center font-catamaran">
            About Us
          </div>
          <div className="max-w-md mx-auto mt-20">
            <LandingPageGradientButton
              label="Get Started With A 7 Day Free Trial"
              color="blue-gradient"
              onClick={() => {
                navigate("/sign-up");
              }}
              fontSize="lg"
              arrow
            />
          </div>
          <div className="flex flex-1 mt-20 text-snow-white font-light text-2xl text-left font-noto">
            As contractors and business owners ourselves, founders Jason and Tim
            saw a need within our own company to better track our team and job
            sites. As we started developing this for ourselves, we realized this
            need was prevalent within the entire snow and landscape industry. We
            set out to create an app specifically for tracking and optimizing in
            field operations. We wanted something simple and easy to use, that
            was the name of the game. Realizing that not everyone is
            technologically savvy, we made it our goal to allow a new user to
            download the app and figure out how it works without any training.
          </div>
          <div className="flex flex-1 mt-8 text-snow-white font-light text-2xl text-left font-noto">
            There were two common pain points within our industry that we wanted
            to address. First being companies of all sizes not having good
            record keeping, with pictures to protect themselves from unnecessary
            exposure to liability. The second being a lack of proof and
            transparency when it came to services rendered. Many clients would
            question when their property was serviced and the company would not
            have good records to show what services were performed and when.
          </div>
          <div className="flex flex-1 mt-8 text-snow-white font-light text-2xl text-left font-noto">
            SnowScape is designed to give business owners a streamlined platform
            to track work being performed in the field in real time, employees
            hours, materials used all the while creating ironclad reports and
            proof as to what was done, by whom and when.
          </div>
          <div className="flex flex-1 mt-8 text-snow-white font-light text-2xl text-left font-noto">
            It's so easy, my 4 year old can figure it out.
          </div>
          <img
            src={SnowRemovalImage}
            className="rounded-2xl mt-8 object-cover object-center h-[300px] md:h-[500px] w-full"
          />
        </div>
      </div>
      {/* <hr className="border-t-2 border-snow-white" />
      <Footer /> */}
    </WithSnowFlakes>
  );
};

export default AboutPage;
