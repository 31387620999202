import React from "react";
import SquareButton from "../../../components/Buttons/SquareButton";
const PricingCard = (props) => {
  return (
    <div
      className={`bg-gradient-to-b rounded-xl py-20 px-4 relative flex-1 flex flex-col justify-between ${
        props.recommended
          ? "border-2 border-landing-page-blue from-landing-page-black to-landing-page-pricing-card-gradient-start"
          : "from-landing-page-pricing-card-gradient-start to-landing-page-black"
      }`}
    >
      {props.recommended && (
        <div className="absolute top-[-32px] left-8 text-snow-white rounded-t-xl px-2 py-1 bg-gradient-to-br from-landing-page-blue to-landing-page-light-blue">
          <span>Recommended</span>
        </div>
      )}
      <div className="flex flex-col">
        <div className="text-center">
          {props.price === "free" ? (
            <span className="text-landing-page-pricing-green font-bold text-5xl">
              Free
            </span>
          ) : (
            <>
              <span className="text-snow-white text-5xl">$</span>
              <span className="text-landing-page-pricing-green font-bold text-6xl">
                {props.price}
              </span>
              <span className="text-snow-white text-2xl">/mo</span>
            </>
          )}
        </div>
        <div className="font-catamaran text-4xl text-snow-white mt-20">
          {props.title}
        </div>
        <div className="">
          <ul className="list-disc list-inside">
            {props.details.map((detail, index) => (
              <li
                key={index}
                className="text-snow-white text-lg font-light mt-4"
              >
                {detail}
              </li>
            ))}
          </ul>
        </div>
        <div className="mt-4">
          <span className="text-snow-white text-sm font-light mt-4">
            {props.subtext}
          </span>
        </div>
      </div>
      <div className="mt-4">
        <SquareButton label={props.buttonText} onClick={props.onClick} />
      </div>
    </div>
  );
};

export default PricingCard;
