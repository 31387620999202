import React from "react";
import AuthLabeledTextInput from "../../components/Inputs/AuthLabeledTextInput";
import { useState } from "react";
import SquareButton from "../../components/Buttons/SquareButton";
import ChoosePlanInput from "./ChoosePlanInput";
import { PricingData } from "../../components/Pricing";
import { useNavigate } from "react-router-dom";
import PersonalInfoFields from "./PersonalInfoFields";
import { useAuthState } from "react-firebase-hooks/auth";
import firebaseAuth from "../../services/firebase/firebaseAuth";
import { useQuery } from "@tanstack/react-query";

const BasicInfoForm = ({ selectedPlan, handleSubmit, currentUser }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [mySelectedPlan, setMySelectedPlan] = useState(selectedPlan);
  const navigate = useNavigate();
  const [firebaseUser, authLoading] = useAuthState(firebaseAuth.auth);

  // If we have a current user and a firebase user - we should check if they already have a company:
  const shouldQueryCompanies =
    currentUser != null && !authLoading && firebaseUser != null;
  const companyQuery = useQuery({
    queryKey: [`user/${currentUser?.uid}/companies`],
    enabled: shouldQueryCompanies,
  });

  // Don't display our form until we have the data we need to load it:
  if (authLoading === true) return null;
  if (companyQuery.isLoading) {
    return null;
  }
  const existingOwnedCompanyId = shouldQueryCompanies
    ? companyQuery.data.userCompanies.find(
        (c) => c.ownerUid === currentUser.uid,
      )?._id
    : null;

  const onFormSubmit = async (e) => {
    setIsLoading(true);
    try {
      const form = e.nativeEvent.target;
      const data = {
        phone: form.phone?.value,
        firstName: form.first_name?.value,
        lastName: form.last_name?.value,
        companyName: form.company_name?.value,
        plan: form.plan?.value,
        email: form.email?.value,
        existingCompanyId: form.existing_company_id?.value,
      };
      if (typeof handleSubmit === "function") {
        await handleSubmit(data);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onFormSubmit(e);
      }}
      className="sign-up-form p-2"
    >
      <h1 className="text-2xl lg:text-3xl font-bold">Sign Up</h1>
      {existingOwnedCompanyId ? (
        <input
          type="hidden"
          value={existingOwnedCompanyId}
          name="existing_company_id"
        />
      ) : (
        <AuthLabeledTextInput
          disabled={false}
          placeholder="Company Name"
          name="company_name"
          label="Company Name"
          required
          autoFocus
        />
      )}
      {currentUser && firebaseUser ? null : <PersonalInfoFields />}
      {selectedPlan ? (
        <input type="hidden" name="plan" value={selectedPlan} />
      ) : (
        <ChoosePlanInput
          name="plan"
          onPlanChange={(plan) => setMySelectedPlan(plan)}
        />
      )}
      <p className="my-2 text-sm">
        You can also{" "}
        <a
          onClick={(e) => {
            e.preventDefault();
            let phone = document.querySelector("[name='phone']")?.value;
            navigate("/sign-up/join?phone=" + encodeURIComponent(phone));
          }}
          className="underline cursor-pointer"
          href="/sign-up/join"
        >
          join an existing company
        </a>{" "}
        if you have the join code.
      </p>

      <SquareButton
        label={
          PricingData.find((p) => p.id === mySelectedPlan)?.price > 0
            ? "Checkout"
            : "Sign Up"
        }
        color="blue"
        loading={isLoading}
        disabled={isLoading}
        className="max-w-none"
        testId="basic-info-submit"
      />
    </form>
  );
};

export default BasicInfoForm;
