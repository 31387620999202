import React from "react";

// Components
import WithSnowFlakes from "../../../components/LandingPage/WithSnowFlakes";
import { Link } from "react-router-dom";
import SelectCompanySrc from "../../../resources/docs/select-company.png";
import DeactivateCompanySrc from "../../../resources/docs/deactivate-company.png";

const DeactivateCompanyPage = () => {
  return (
    <WithSnowFlakes>
      <div className="flex flex-col w-full items-center px-4 py-20 overflow-scroll ">
        <div className="w-full max-w-[1200px] text-lg">
          <h1 className="text-snow-white font-bold text-5xl text-center font-catamaran">
            How To De-activate a Company or Subcontractor
          </h1>
          <div className="flex flex-1 mt-20 text-snow-white text-left font-noto">
            To save on costs - or to simply keep your life organized, you can
            deactivate any companies or subcontractors you are not using. If you
            deactivate a company we will save all your data and you can
            re-activate it anytime you want. However, while a company is
            deactivated, you won't be able to record new data or service
            records.
          </div>
          <h2 className="font-bold text-2xl font-catamaran mt-2">
            Instructions
          </h2>
          <ol className="list-decimal list-inside">
            <li>
              Login to our Admin Dashboard on the website and go to{" "}
              <Link className="underline" to="/admin/settings/company">
                Settings &gt; Company
              </Link>
            </li>
            <li>
              Select the company you want to deactivate
              <img
                className="mt-2 mb-10 ml-10 w-[600px]"
                src={SelectCompanySrc}
              />
            </li>
            <li>
              Then just press the deactivate button on the top right.
              <img
                className="mt-2 mb-10 ml-10 w-[600px]"
                src={DeactivateCompanySrc}
              />
            </li>
          </ol>
        </div>
      </div>
    </WithSnowFlakes>
  );
};

export default DeactivateCompanyPage;
