import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { connect } from "react-redux";

// Components
import LabeledTextboxInput from "../Inputs/LabeledTextboxInput";

// Service
import axiosStripe from "../../services/axios/stripe";
import StandardButton from "../Buttons/StandardButton";
import axios from "axios";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#082D4A",
      color: "#363636",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "18px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#363636" },
      "::placeholder": { color: "#829399" },
    },
    invalid: {
      iconColor: "#B73827",
      color: "#B73827",
    },
  },
};

async function submitPaymentMethodToStripe(
  stripe,
  elements,
  nameOnCard,
  currentUser,
) {
  const result = await stripe.createPaymentMethod({
    type: "card",
    card: elements.getElement(CardElement),
    billing_details: {
      name: nameOnCard,
    },
  });

  if (result.error) {
    throw result.error;
  }

  // Attach the payment method to the customer as the default
  const res = await axios.post("stripe/customer/payment-method/add", {
    paymentMethod: result.paymentMethod,
    uid: currentUser.uid,
  });
  return res.data;
}

const AddPaymentMethodForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [cardReady, setCardReady] = useState(false);
  const [error, setError] = useState(null);
  const [nameOnCard, setNameOnCard] = useState("");
  const [submitLoading, setSubmitLoading] = useState("");

  const handleAddPaymentMethod = async (event) => {
    setSubmitLoading(true);
    event.preventDefault();

    // Use elements.getElement to get the CardElement
    // Collect card details and create a payment method
    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        name: nameOnCard,
      },
    });

    if (result.error) {
      setError(result.error.message);
      setSubmitLoading(false);
      return;
    }

    // Attach the payment method to the customer as the default
    axiosStripe.addPaymentMethod(
      {
        paymentMethod: result.paymentMethod,
        uid: props.currentUser.uid,
      },
      () => {
        setError(null);
        console.log("Payment method added successfully:", result.paymentMethod);
        setSubmitLoading(false);
        props.handleSuccess();
      },
      (err) => {
        console.error(err);
        setError("Failed to attach payment method");
      },
    );
  };

  return (
    <form onSubmit={handleAddPaymentMethod}>
      {/* TODO: Add more validation for name matching Card - Maybe replace with PaymentElement? */}
      <LabeledTextboxInput
        label="Name On Card"
        placeholder="John Doe"
        setValue={setNameOnCard}
        value={nameOnCard}
      />
      <div className="w-full rounded-md border-2 border-snow-primary py-1.5 px-2.5 mb-8 h-12 flex items-center">
        <CardElement
          className="w-full"
          id="card-element"
          options={CARD_OPTIONS}
          onReady={() => {
            setCardReady(true);
          }}
        />
      </div>
      <StandardButton
        color="green"
        label="Add Payment Method"
        loading={submitLoading}
        disabled={!cardReady || nameOnCard.length === 0}
        onClick={handleAddPaymentMethod}
      />
      {error && <div style={{ color: "red" }}>{error}</div>}
    </form>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(AddPaymentMethodForm);
export { submitPaymentMethodToStripe };
