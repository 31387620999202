import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";

// Components
import HeaderBar from "../../../components/Header/HeaderBar";
import LoadingIcon from "../../../components/Loaders/LoadingIcon";
import CreateMaterialModal from "../../../components/Modals/CreateMaterialModal";
import MultipleChoiceModal from "../../../components/Modals/MultipleChoiceModal";
import MaterialDataTable from "../../../components/Tables/MaterialDataTable";

// Services
import axiosMaterials from "../../../services/axios/materials";

const AdminMaterialsPage = (props) => {
  const [companyMaterials, setCompanyMaterials] = useState([]);
  const [displayChooseSeasonModal, setDisplayChooseSeasonModal] =
    useState(false);
  const [displayCreateMaterialModal, setDisplayCreateMaterialModal] =
    useState(false);

  const [materialsTableData, setMaterialsTableData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [newMaterialSeason, setNewMaterialSeason] = useState(null);

  const [displayMaterialDetailModal, setDisplayMaterialDetailModal] =
    useState(false);
  const [materialDetailBeingDisplayed, setMaterialDetailBeingDisplayed] =
    useState(null);

  // const [displayUpdateMaterialModal, setDisplayUpdateMaterialModal] =
  //     useState(false);
  // const [materialBeingUpdated, setMaterialBeingUpdated] = useState(null);

  const fetchData = useCallback(async (companyId) => {
    setLoading(true);

    const materialData = await axiosMaterials.getCompanyMaterials({
      companyId,
    });

    setCompanyMaterials(materialData);

    setLoading(false);
  }, []);
  useEffect(() => {
    fetchData(props.currentUser.currentCompanyId);
  }, [fetchData, props.currentUser.currentCompanyId]);

  // Automatically format data for table each time its changed
  useEffect(() => {
    formatMaterialsTable(companyMaterials);
  }, [companyMaterials]);

  // Format the Material Table
  const formatMaterialsTable = (data) => {
    // If data not passed in, skip formatting
    if (!data) return;

    const formattedData = {
      labels: ["Name", "Unit", "Description", "Actions"],
      content: [],
    };

    // Add each row of material
    data.forEach((mat) => {
      formattedData.content.push({
        data: [mat.name, mat.baseUnit, mat.description],
        options: [
          // {
          //     label: 'Edit',
          //     operation: () => handleOpenUpdateMaterialModal(mat),
          // },
          {
            label: "Edit",
            operation: () => {
              setMaterialDetailBeingDisplayed(mat);

              setDisplayMaterialDetailModal(true);
            },
          },
        ],
        season: mat.season,
      });
    });

    setMaterialsTableData(formattedData);
  };

  const handleCreateNewMaterials = async (newMaterial) => {
    const isNew = !("_id" in newMaterial);
    const data = await axiosMaterials.createOrUpdateMaterial({
      ...newMaterial,
      season: newMaterialSeason,
      companyId: props.currentUser.currentCompanyId,
    });

    if (isNew) {
      await fetchData();
    } else {
      setCompanyMaterials(
        companyMaterials.map((m) => {
          return m._id == data._id ? data : m;
        }),
      );
    }

    // both types use this function
    setDisplayCreateMaterialModal(false);
    setDisplayMaterialDetailModal(false);

    setLoading(false);
  };

  // Note: Commented out all update materials functionality - uncomment and test if
  // const handleOpenUpdateMaterialModal = (material) => {
  //     setMaterialBeingUpdated(material);
  //     setDisplayUpdateMaterialModal(true);
  // };

  // const handleUpdateMaterial = async (updatedMaterial) => {
  //     const data = await axiosMaterials.updateMaterial({
  //         updatedMaterial,
  //         companyId: props.currentUser.currentCompanyId,
  //     });

  //     setCompanyMaterials(data.companyMaterials);
  //     setMaterialBeingUpdated(null);
  //     setDisplayUpdateMaterialModal(false);
  //     setLoading(false);
  // };

  const handleDeleteMaterial = async (materialId) => {
    // Delete material from mongo
    await axiosMaterials.deleteMaterial({
      materialId,
    });

    // Remove deleted from data displayed
    const remainingMaterials = companyMaterials.filter(
      (item) => materialId !== item._id,
    );

    setCompanyMaterials(remainingMaterials);
    setDisplayMaterialDetailModal(false);
    setLoading(false);
  };

  return (
    <div className="flex flex-col items-center">
      <HeaderBar
        button={{
          color: "blue",
          enabled: true,
          label: "Create",
          onClick: () => setDisplayChooseSeasonModal(true),
        }}
        title="Materials"
      />
      {loading ? (
        <LoadingIcon />
      ) : (
        <div className="flex flex-1 flex-col w-full p-10 pb-40 items-center overflow-y-scroll">
          <MaterialDataTable
            data={materialsTableData}
            setMaterialDetailBeingDisplayed={(index) => {
              setMaterialDetailBeingDisplayed(companyMaterials[index]);
              setDisplayMaterialDetailModal(true);
            }}
            subtitle="These are materials your company is tracking"
            title="My Materials"
          />
        </div>
      )}
      {/* Create Material Modal */}
      <CreateMaterialModal
        adjustForSidebar
        enableCreate={true}
        onClose={() => setDisplayCreateMaterialModal(false)}
        onCreateMaterial={handleCreateNewMaterials}
        open={displayCreateMaterialModal}
      />
      {/* Update Material Modal */}
      {/* <CreateMaterialModal
                adjustForSidebar
                enableDelete={true}
                initialMaterialValue={materialBeingUpdated}
                onClose={() => setDisplayUpdateMaterialModal(false)}
                onCreateMaterial={handleUpdateMaterial}
                onDelete={handleDeleteMaterial}
                open={displayUpdateMaterialModal}
                title='Update Material'
            /> */}

      {/* Choose Season Modal */}
      <MultipleChoiceModal
        closeModal={() => setDisplayChooseSeasonModal(false)}
        open={displayChooseSeasonModal}
        options={[
          {
            color: "green",
            label: "Landscape",
            onClick: () => {
              setNewMaterialSeason("landscape");
              setDisplayChooseSeasonModal(false);
              setDisplayCreateMaterialModal(true);
            },
          },
          {
            color: "blue",
            label: "Snow",
            onClick: () => {
              setNewMaterialSeason("snow");
              setDisplayChooseSeasonModal(false);
              setDisplayCreateMaterialModal(true);
            },
          },
        ]}
        subtitle="Choose a season to create a new material for."
        title="Choose Season"
      />

      {/* View Material Details */}
      <CreateMaterialModal
        adjustForSidebar
        disableEdit={false}
        enableDelete={true}
        initialMaterialValue={materialDetailBeingDisplayed}
        onClose={() => {
          setDisplayMaterialDetailModal(false);
          setMaterialDetailBeingDisplayed(null);
          setNewMaterialSeason(null);
        }}
        onCreateMaterial={handleCreateNewMaterials}
        onDelete={handleDeleteMaterial}
        open={displayMaterialDetailModal}
        title={materialDetailBeingDisplayed?.name || "Material"}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(AdminMaterialsPage);
