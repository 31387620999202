import axios from "axios";
import errors from "../errors";

const noop = () => {};

const axiosStripe = {
  addPaymentMethod: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.post("stripe/customer/payment-method/add", data);

      if (res.data.success) {
        console.log("Successfully removed payment method!");
        onSuccess(res.data);
      } else {
        throw res.data.message;
      }
    } catch (error) {
      console.error("Stripe Remove Payment Method Error: ", error);
      errors.report(error);
      onFail(error);
    }
  },
  createPortalSession: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.post("stripe/create-portal-session", data);

      if (res.data.success) {
        onSuccess(res.data);
      }
    } catch (error) {
      console.error("Stripe Subscription Error: ", error);
      errors.report(error);
      onFail(error);
    }
  },
  getAddOns: async (onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.get("stripe/addons");

      onSuccess(res.data);
    } catch (error) {
      console.error("Stripe Subscription Error: ", error);
      errors.report(error);
      onFail(error);
    }
  },
  getCustomerById: async (data) => {
    const response = await axios.post("stripe/customer", data);
    return response.data.customer;
  },
  getCustomerPaymentMethod: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.post("stripe/customer/payment-method", data);
      if (res.data.success) {
        onSuccess(res.data);
      }
    } catch (error) {
      console.error("Stripe Subscription Error: ", error);
      errors.report(error);
      onFail(error);
    }
  },
  getCustomerSubscriptions: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.post("stripe/customer/subscriptions", data);

      if (res.data.success) {
        onSuccess(res.data);
      }
    } catch (error) {
      console.error("Stripe Subscription Error: ", error);
      errors.report(error);
      onFail(error);
    }
  },
  makeOneTimePayment: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.post("stripe/make-payment", data);

      if (res.data.success) {
        console.log("Successful payment!");
        onSuccess(res.data);
      }
    } catch (error) {
      console.error("Stripe Payment Error: ", error);
      errors.report(error);
      onFail(error);
    }
  },
  // purchaseAddons: async (data, onSuccess = noop, onFail = noop) => {
  //     try {
  //         const res = await axios.post('stripe/purchase/addons', data);
  //         if (res.data.success) {
  //             console.log('Successful purchase of addons!');
  //             onSuccess(res.data);
  //         }
  //     } catch (error) {
  //         console.error('Stripe Subscription Error: ', error);
  //         onFail(error);
  //     }
  // },
  purchaseSubscription: async (data) => {
    try {
      const res = await axios.post("stripe/purchase/subscription", data);

      if (res.data.success) {
        return res.data;
      }
      throw Error("Failed to make checkout session");
    } catch (error) {
      console.error("Stripe Subscription Error: ", error);
      errors.report(error);
      throw error;
    }
  },
  removePaymentMethod: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.post(
        "stripe/customer/payment-method/remove",
        data,
      );

      if (res.data.success) {
        console.log("Successfully removed payment method!");
        onSuccess(res.data);
      } else {
        throw res.data.message;
      }
    } catch (error) {
      console.error("Stripe Remove Payment Method Error: ", error);
      errors.report(error);
      onFail(error);
    }
  },
  removeSubscription: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.post("stripe/subscription/unsubscribe", data);

      if (res.data.success) {
        console.log("Successful unsubscribe!");
        onSuccess(res.data);
      } else {
        throw res.data.message;
      }
    } catch (error) {
      console.error("Stripe Unsubscribe Error: ", error);
      errors.report(error);
      onFail(error);
    }
  },
  updateSubscription: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.post("stripe/customer/update-subscription", data);

      if (res.data.success) {
        onSuccess(res.data);
      } else {
        throw res.data.message;
      }
    } catch (error) {
      console.error("Stripe Update Subscription Error: ", error);
      errors.report(error);
      alert(error.response.data);
      onFail(error);
    }
  },

  purchaseUpgrade: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.post("stripe/purchase/upgrade", data);

      if (res.data.success) {
        onSuccess(res.data);
      } else {
        throw res.data.message;
      }
    } catch (error) {
      console.error("Stripe Purchase Upgrade Error: ", error);
      errors.report(error);
      alert(error.response.data);
      onFail(error);
    }
  },
};

export default axiosStripe;
