import React from "react";
import MoonLoader from "react-spinners/MoonLoader";

// Resources
import CrossCircleIcon from "../../resources/cross-circle-red.svg";

const ImageNoteItem = (props) => {
  return (
    <>
      <a
        href={props.uri}
        className="relative w-52 h-52 flex-shrink-0 bg-full-black rounded-md block"
      >
        {props.loading ? (
          <div className="absolute top-1 left-1 bottom-1 right-1 z-10">
            <div className="flex w-full h-full justify-center items-center">
              <MoonLoader color="#fffafa" speedMultiplier={0.5} />
            </div>
          </div>
        ) : null}

        {props.enableDelete && props.uri && !props.loading ? (
          <div
            onClick={(e) => {
              e.preventDefault();
              props.deletePressed(e);
            }}
            className={`absolute top-0 right-0 z-10 hover:opacity-70 active:opacity-40`}
          >
            <img className="w-5" src={CrossCircleIcon} alt="delete" />
          </div>
        ) : null}

        {props.note ? (
          <div
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              alert(props.note);
            }}
            className="absolute bottom-0 right-0 left-0 text-full-white p-2 cursor-pointer"
            style={{
              backgroundColor: "rgba(82,82,82, .7)",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
            title={props.note}
          >
            {props.note}
          </div>
        ) : null}

        <img
          alt="img-note"
          className={`w-full h-full rounded-md border-x border-y border-snow-primary object-cover ${
            props.loading ? "opacity-40" : null
          }`}
          src={props.uri}
        />
      </a>
    </>
  );
};

export default ImageNoteItem;
