import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import SplitForm from "../../components/SplitForm";
import { useState } from "react";
import SquareButton from "../../components/Buttons/SquareButton";
import axios from "../../services/axios/backendAxios";
import { useAuthState } from "react-firebase-hooks/auth";
import firebaseAuth from "../../services/firebase/firebaseAuth";
const StripeSuccessPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let session_id = searchParams.get("session_id");
  const [submittingData, setSubmittingData] = useState(true);
  const [user] = useAuthState(firebaseAuth.auth);

  useEffect(() => {
    if (user) {
      let timeoutHandle = null;
      axios
        .post("user/setStripeCustomerId", {
          sessionId: session_id,
        })
        .then(() => {
          // Show the success for brief moment before going to the dashboard automatically
          timeoutHandle = setTimeout(() => {
            navigate("/admin/dashboard?show_purchase_success=1");
          }, 1500);
        })
        .finally(() => {
          setSubmittingData(false);
        });
      return () => {
        clearTimeout(timeoutHandle);
      };
    }
  }, [navigate, session_id, user]);

  return (
    <>
      <SplitForm>
        <div className="grid h-full">
          <div className="text-full-white place-self-center">
            <h1 className="text-2xl lg:text-3xl font-bold">Success</h1>
            <p>Thank you for your purchase. We will email you a receipt.</p>
            <SquareButton
              loading={submittingData}
              onClick={() =>
                navigate("/admin/dashboard?show_purchase_success=1")
              }
            >
              Go to Dashboard
            </SquareButton>
          </div>
        </div>
      </SplitForm>
    </>
  );
};
export default StripeSuccessPage;
