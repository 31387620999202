import React from "react";
import RoundButton from "../Buttons/RoundButton";
import ModalWrapper from "./ModalWrapper";

const UnsubscribeConfirmModal = (props) => {
  return (
    <>
      {props.open ? (
        <ModalWrapper clickOff={props.closeModal}>
          <div
            className={`flex flex-col w-96 rounded-xl bg-snow-white drop-shadow-2xl px-12 pt-12 pb-6 overflow-x-scroll ${
              props.adjustForSidebar ? "ml-60" : null
            }`}
          >
            <div className="flex flex-row justify-between w-full">
              <div className="text-2xl font-bold font-snow-primary">
                Cancel Subscription
              </div>
            </div>
            <div className="text-base font-light mb-4">
              Are you sure you want to unsubscribe from this subscription?
              Instead of canceling you can switch to our free plan and keep your
              data!
            </div>

            <div className="flex flex-col flex-1 w-full">
              <div className="mb-2">
                <RoundButton
                  color="red"
                  fullWidth
                  label="Cancel Subscription"
                  loading={null}
                  onClick={props.onEndSubscription}
                  data-testid="confirm-cancel"
                />
              </div>
              <div className="mb-2">
                <RoundButton
                  color="blue"
                  fullWidth
                  label="I've changed my mind"
                  loading={null}
                  onClick={props.onCancel}
                  data-testid="confirm-nevermind"
                />
              </div>
            </div>
          </div>
        </ModalWrapper>
      ) : null}
    </>
  );
};

export default UnsubscribeConfirmModal;
