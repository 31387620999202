import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import HeaderBar from "../../../components/Header/HeaderBar";
import LabeledTextboxInput from "../../../components/Inputs/LabeledTextboxInput";
import { updateUser } from "../../../store/reducers/currentUserSlice";
import flash from "../../../services/flash";
import axios from "axios";

const AdminCreateCompanyPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [companyName, setCompanyName] = useState("");

  const handleCreateCompany = async () => {
    try {
      await axios.post("company/add", { name: companyName, uid: props.currentUser.uid });
      let userData = (await axios.get("user/" + props.currentUser.uid)).data;
      dispatch(updateUser(userData));
      navigate(-1);
    } catch (err) {
      flash.error(err.response?.data);
    }
  };

  return (
    <div className="flex flex-col items-center">
      <HeaderBar title="Create Company/Subcontractor" buttons={[
        {
          label: 'Save',
          color: 'green',
          onClick: handleCreateCompany,
        }
      ]} />
      <div className="flex flex-1 flex-col w-full p-10 overflow-y-scroll">
        <div className="flex flex-row w-full justify-between items-start mb-4">
          <div className="text-lg pr-4">
            Create a new company or subcontractor. You can add a new set of
            properties, routes and users to each one you create.
          </div>
        </div>

        <LabeledTextboxInput
          label="Company Name"
          placeholder="Name your company..."
          setValue={(val) => {
            setCompanyName(val);
          }}
          value={companyName}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(AdminCreateCompanyPage);
