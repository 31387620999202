import React, { useState } from "react";
import EllipsesBlue from "../../resources/ellipses-blue.svg";

export default function DropDownMenu({ options, className }) {
  const [displayDropdown, setDisplayDropdown] = useState(false);
  return (
    <div
      className={`relative ${className}`}
      onClick={() => setDisplayDropdown(!displayDropdown)}
      data-testid="dropdown-menu-trigger"
    >
      <img alt="menu" className="w-8 hover:opacity-60" src={EllipsesBlue} />
      {displayDropdown ? (
        <>
          {/* Overlay to close on clickoff */}
          <div
            className=" fixed left-0 w-full h-full z-10"
            onClick={() => setDisplayDropdown(false)}
          ></div>
          <ul className="absolute right-0 top-6 list-none bg-snow-white border-[1.5px] border-snow-primary rounded-lg p-2 z-20 drop-shadow-lg">
            {options.map((option, key) => (
              <li
                className={`mx-3 my-1 cursor-pointer hover:opacity-60 whitespace-nowrap ${
                  option.color === "red"
                    ? "text-snow-red"
                    : option.color === "green"
                      ? "text-snow-green"
                      : "text-snow-blue-3"
                }`}
                key={key}
                onClick={() => {
                  // Run passed in operation
                  option.operation();

                  // Close dropdown
                  setDisplayDropdown(false);
                }}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </>
      ) : null}
    </div>
  );
}
