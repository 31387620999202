import axios from "axios";
import errors from "../errors";

const noop = () => {};

const axiosCompanies = {
  addAdmin: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.post(
        "company/" + data.companyId + "/user/" + data.uid + "/makeadmin",
      );

      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  addCommonService: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.post(
        "company/" + data.companyId + "/services/add",
        data,
      );

      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  addCompany: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.post("company/add", data);

      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error.response.data);
    }
  },
  addCompanyAsync: async (data) => {
    try {
      const res = await axios.post("company/add", data);
      return res.data;
    } catch (error) {
      errors.report(error);
      throw error;
    }
  },
  addCompanyLicense: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.post(
        "company/" + data.companyId + "/license/add",
        data,
      );

      if (res.data.success) {
        onSuccess(res.data);
      } else {
        onFail(res);
      }
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  checkIfPaid: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.post(
        "company/" + data.companyId + "/confirm-payment-status",
        data,
      );

      if (res.data.success) {
        onSuccess(res.data);
      }
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  getAllAdmins: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.get(
        "company/" + data.companyId + "/admins?take=10000",
      );

      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  getAllEmployees: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.get(
        "company/" + data.companyId + "/employees?take=10000",
      );

      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  getCommonServices: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.get("company/" + data.companyId + "/services");

      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  getCommonServicesAsync: async (companyId, abortSignal) => {
    try {
      const res = await axios.get("company/" + companyId + "/services", {
        signal: abortSignal,
      });
      return res.data;
    } catch (error) {
      errors.report(error);
      throw error;
    }
  },
  getAllUsers: async (data, onSuccess = noop, onFail = noop) => {
    try {
      if (!data.companyId) {
        throw new Error("getAllUsers called without a proper companyId");
      }
      const res = await axios.get(
        "company/" + data.companyId + "/users?take=10000",
      );

      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  getCompanyById: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.get("company/" + data.companyId);

      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  inviteBySMS: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.post(
        "company/" + data.companyId + "/invite",
        data,
      );

      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  joinCompany: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.post("company/join", data);

      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  removeAdmin: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.post(
        "company/" + data.companyId + "/user/" + data.uid + "/admin/remove",
      );

      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  removeCompanyLicense: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.post(
        "company/" + data.companyId + "/license/remove",
        data,
      );

      if (res.data.success) {
        onSuccess(res.data);
      } else {
        onFail(res.data.message);
      }
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  removeEmployeeFromCompany: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.post(
        "company/" + data.companyId + "/user/remove",
        data,
      );

      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  updateCommonServices: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.post(
        "company/" + data.companyId + "/services/update",
        data,
      );

      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  updateCompanyById: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.post(
        "company/" + data.companyId + "/update",
        data,
      );
      onSuccess(res.data);
    } catch (error) {
      onFail(error);
    }
  },
};

export default axiosCompanies;
