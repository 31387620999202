import React, { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useSearchParams } from "react-router-dom";

// Component
import AuthComponent from "../../components/Auth/AuthComponent";

// Services
import firebaseAuth from "../../services/firebase/firebaseAuth";
import SplitForm from "../../components/SplitForm";
import { useDispatch } from "react-redux";
import { updateUser } from "../../store/reducers/currentUserSlice";
import axios from "axios";

const AdminLoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const phone = searchParams.get("phone");

  const [firebaseUser, loading] = useAuthState(firebaseAuth.auth);

  useEffect(() => {
    if (loading) return;
    if (firebaseUser) {
      axios
        .get("user/" + firebaseUser.uid)
        .then((res) => {
          let userData = res.data;
          if (userData._id) {
            dispatch(updateUser(res.data));
            navigate("/admin");
          } else {
            // We have a firebase account but we have not completed the sign-up flow yet:
            navigate("/sign-up");
          }
        })
        .catch(() => {
          navigate("/sign-up");
        });
    }
  }, [firebaseUser, loading, navigate, dispatch]);

  return (
    <SplitForm>
      <div className="grid h-full">
        <div className="text-full-white place-self-center py-20">
          <p>{firebaseUser?.uid}</p>
          <AuthComponent
            subtext="Don't have an account? Sign Up."
            subtextUrl="/sign-up"
            title="Login"
            phone={phone}
            subtitle="Welcome back! Please enter your details."
          />
        </div>
      </div>
    </SplitForm>
  );
};

export default AdminLoginPage;
