import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

// Components
import JobHistoryCard from "../../../components/Cards/JobHistoryCard";
import SearchBar from "../../../components/Filters/SearchBarWithFilters";
import HeaderBar from "../../../components/Header/HeaderBar";
import LoadingIcon from "../../../components/Loaders/LoadingIcon";

// Services
import useJobHistoryReport from "../../../hooks/useJobHistoryReport";
import axiosJobHistory from "../../../services/axios/jobHistory";
import InfiniteScroll from "react-infinite-scroll-component";
import errors from "../../../services/errors";
import flash from "../../../services/flash";
import PropertyHistoryFilters from "../../../components/Filters/PropertyHistoryFilters";
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
function buildQueryParamString(filters, searchTerm) {
  let cleanedUp = Object.keys(filters)
    .filter(
      (key) =>
        filters[key] != null &&
        (!Array.isArray(filters[key]) || filters[key].length > 0),
    )
    .reduce((obj, key) => {
      obj[key] = filters[key];
      return obj;
    }, {});

  // Handle date range manually:
  delete cleanedUp.dateRange;
  if (filters.dateRange) {
    cleanedUp.firstDate = filters.dateRange?.firstDate.toISOString();
    cleanedUp.secondDate = filters.dateRange?.secondDate.toISOString();
  }
  if (searchTerm) cleanedUp.searchTerm = searchTerm;
  let queryParams = new URLSearchParams(cleanedUp).toString();
  return queryParams;
}
/**
 * Get a filter object from the query parameters in the url
 * @param {URLSearchParams} queryParams
 */
function getFilterArray(queryParams) {
  let obj = {};
  let arrays = ["cities", "jobs", "services", "operators", "routes"];
  let simpleValues = ["season"];
  let booleanValues = ["flaggedForReview"];
  for (const key of arrays) {
    if (queryParams.has(key)) {
      obj[key] = queryParams.get(key).split(",");
    }
  }
  for (const key of simpleValues) {
    if (queryParams.has(key)) {
      obj[key] = queryParams.get(key);
    }
  }
  for (const key of booleanValues) {
    if (queryParams.has(key)) {
      obj[key] = queryParams.get(key) === "true";
    }
  }
  if (queryParams.has("firstDate") && queryParams.has("secondDate")) {
    obj["dateRange"] = {
      firstDate: new Date(queryParams.get("firstDate")),
      secondDate: new Date(queryParams.get("secondDate")),
    };
  }
  return obj;
}
const AdminJobHistoryPage = (props) => {
  console.log("rendering AdminJobHistoryPage");
  let queryParams = useQuery();
  let queryFilters = getFilterArray(queryParams);
  const navigate = useNavigate();

  const [currentFilter, setCurrentFilter] = useState(queryFilters);
  const [currentSearchTerm, setCurrentSearchTerm] = useState(
    queryParams.has("searchTerm") ? queryParams.get("searchTerm") : null,
  );
  const [records, setRecords] = useState([]);
  const [lastPage, setLastPage] = useState(false);
  const [nextPageToLoad, setNextPageToLoad] = useState(0);
  const [perPage] = useState(30);
  const [loading, setLoading] = useState(true);

  const { createJobHistoryReport } = useJobHistoryReport({
    companyId: props.currentUser.currentCompanyId,
    dateRange: {
      startDate: currentFilter.dateRange?.firstDate,
      endDate: currentFilter.dateRange?.secondDate,
    },
    services: currentFilter.services,
    jobs: currentFilter.jobs,
    cities: currentFilter.cities,
    flaggedForReview: currentFilter.flaggedForReview,
    season: currentFilter.season,
    searchPhrase: currentSearchTerm,
    operators: currentFilter.operators,
    routes: currentFilter.routes,
  });

  const loadPage = useCallback(
    async (pageToLoad, searchTerm, filters, abortSignal) => {
      try {
        const promise = axiosJobHistory.getAllJobHistoryV2(
          props.currentUser.currentCompanyId,
          searchTerm,
          filters,
          perPage,
          pageToLoad * perPage,
          abortSignal,
        );
        setNextPageToLoad(pageToLoad + 1); // do this before we await to reduce the risk of loading the same page twice
        const results = await promise;
        if (abortSignal == null || !abortSignal.aborted) {
          if (results.length > 0) {
            if (pageToLoad === 0) {
              setRecords([...results]);
            } else {
              setRecords((r) => [...r, ...results]);
            }
          }
          if (results.length < perPage) {
            setLastPage(true);
          }
        }
      } catch (err) {
        if (err.code === "ERR_CANCELED") {
          console.debug("axios request aborted - ignoring");
        } else {
          errors.report(err);
          flash.error("Had an error loading the next page of records");
        }
      } finally {
        setLoading(false);
      }
    },
    [perPage, props.currentUser],
  );

  useEffect(() => {
    const abort = new AbortController();
    setRecords([]);
    loadPage(0, currentSearchTerm, currentFilter, abort.signal);
    return () => {
      abort.abort();
    };
  }, [props.currentUser, loadPage]);

  const nextPage = async (abortSignal) => {
    if (lastPage) {
      return;
    }
    await loadPage(
      nextPageToLoad,
      currentSearchTerm,
      currentFilter,
      abortSignal,
    );
  };

  const reloadDataWithNewCriteria = async (searchTerm, filters) => {
    setRecords([]);
    setLoading(true);
    setLastPage(false);
    await loadPage(0, searchTerm, filters);
  };

  const onSearch = async (searchTerm) => {
    let queryParams = buildQueryParamString(currentFilter, searchTerm);
    navigate("/admin/property/history?" + queryParams, {}); // update the url query so that back / forward work

    setCurrentSearchTerm(searchTerm);
    await reloadDataWithNewCriteria(searchTerm, currentFilter);
  };

  const updateFilters = async (newFilters) => {
    let queryParams = buildQueryParamString(newFilters, currentSearchTerm);
    navigate("/admin/property/history?" + queryParams, {}); // update the url query so that back / forward work

    // Update the state and load new data:
    setCurrentFilter(newFilters);
    await reloadDataWithNewCriteria(currentSearchTerm, newFilters);
  };

  return (
    <>
      <div className="flex flex-col items-center">
        <HeaderBar
          title="Property History"
          buttons={[
            {
              color: "blue",
              label: "Export",
              onClick: createJobHistoryReport,
            },
          ]}
        />
        <div className="w-full px-10">
          <SearchBar onSearch={onSearch}>
            <PropertyHistoryFilters
              onSubmitFilters={updateFilters}
              filters={currentFilter}
            />
          </SearchBar>
          {loading ? (
            <LoadingIcon />
          ) : (
            <>
              {/* Render all Property History */}
              <InfiniteScroll
                dataLength={records.length}
                next={nextPage}
                hasMore={!lastPage}
                loader={<h4>Loading...</h4>}
                className="w-full"
                style={{ width: "100%" }}
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    No more records matching your filters
                  </p>
                }
              >
                {records.map((job, key) => (
                  <JobHistoryCard
                    address={job.address}
                    completedBy={job.completedBy}
                    endTime={job.endTime}
                    key={key}
                    onClick={() => navigate(job._id)}
                    name={job.name}
                    showFlag={job.flaggedForReview}
                  />
                ))}
              </InfiniteScroll>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(AdminJobHistoryPage);
