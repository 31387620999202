import React from "react";
import { connect } from "react-redux";
import CheckMarkIcon from "../../resources/check-mark-green.svg";
import PencilBlue from "../../resources/pencil-blue.svg";

function EmployeeCard(props) {
  const e = props.employee;
  const role = e.uid
    ? e.companies[props.currentCompanyId].role
    : "Time clock only";
  return (
    <div
      className="flex flex-row w-full rounded-xl bg-snow-white drop-shadow py-3 px-6 mb-4 hover:opacity-80 active:opacity-40"
      onClick={props.onClick}
    >
      <div className="flex flex-col flex-1 h-full">
        <div className="text-2xl font-bold font-snow-primary">
          {e.firstName} {e.lastName}
        </div>
        {e.phoneNumber ? (
          <div className="font-md">
            <b>Phone Number:</b> {e.phoneNumber}
          </div>
        ) : null}
        {role ? (
          <div className="font-md">
            <b>Role:</b> {role}
          </div>
        ) : null}
      </div>
      {props.showEdit ? (
        <div
          className="flex w-16 justify-end items-center hover:opacity-60 active:opacity-40"
          onClick={(e) => {
            props.onRightIconClicked();
            // Don't run card pressed function
            e.stopPropagation();
          }}
        >
          <img className="w-7" src={PencilBlue} alt="edit" />
        </div>
      ) : null}
      {props.showCheckMark ? (
        <div className="flex w-16 justify-end items-center" onClick={null}>
          <img className="w-7" src={CheckMarkIcon} alt="edit" />
        </div>
      ) : null}
    </div>
  );
}
const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};
export default connect(mapStateToProps)(EmployeeCard);
