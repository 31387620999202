import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useCallback, useMemo, useState } from "react";
import { CardButton } from "./PaymentMethod";
import { submitPaymentMethodToStripe } from "../Stripe/AddPaymentMethodForm";
import flash from "../../services/flash";
import StandardButton from "../Buttons/StandardButton";
import UnsubscribeConfirmModal from "../Modals/UnsubscribeConfirmModal";
import UnsubscribeReasonModal from "../Modals/UnsubscribeReasonModal";
import axiosStripe from "../../services/axios/stripe";
import axiosContact from "../../services/axios/contact";
import axios from "axios";
import errors from "../../services/errors";
import { useDispatch } from "react-redux";
import { openManageSubscriptionModal } from "../../store/reducers/metadataSlice";
import TrashCanIcon from "../../resources/trash-can-red.svg";
import PencilIcon from "../../resources/pencil-blue.svg";
import SubtleButton from "../Buttons/SubtleButton";

export default function Subscriptions({
  currentUser,
  subscriptionInformation,
  productInformation,
  onSubscriptionChange,
  hasPaymentMethod = true,
}) {
  const dispatch = useDispatch();
  const [displayUnsubscribeModal, setDisplayUnsubscribeModal] = useState(false);
  const [displayUnsubscribeReasonModal, setDisplayUnsubscribeReasonModal] =
    useState(false);
  const [unsubscribeSubscriptionId, setUnsubscribeSubscriptionId] =
    useState(false);
  const subItems = useMemo(() => {
    // TODO: This doesn't quite reflect reality accurately since it implies that these items are separate subscriptions, which they might not be.
    let result = [];
    for (const sub of subscriptionInformation ?? []) {
      for (const sub_item of sub.items.data) {
        result.push({
          name:
            productInformation[sub_item.plan.product].metadata.name ??
            productInformation[sub_item.plan.product].name,
          allowedCompanies:
            Number(
              productInformation[sub_item.plan.product].metadata.companies ?? 0,
            ) * Number(sub_item.quantity),
          allowedUsers:
            Number(
              productInformation[sub_item.plan.product].metadata.users ?? 0,
            ) * Number(sub_item.quantity),
          startDate: new Date(sub.start_date * 1000),
          status: sub.cancel_at_period_end
            ? "End on " + new Date(sub.cancel_at * 1000).toLocaleDateString()
            : sub.status.charAt(0).toUpperCase() + sub.status.slice(1),
          id: sub.id,
        });
      }
    }
    return result;
  }, [productInformation, subscriptionInformation]);

  const handleUnsubscribe = useCallback(
    async (subscriptionId, message) => {
      try {
        // We don't want the unsubscribe to fail if the contact request fails - so lets wrap it in it's own try block:
        try {
          const customer = await axiosStripe.getCustomerById({
            uid: currentUser.uid,
          });

          await axiosContact.sendContactUsEmail({
            email: customer.email,
            message: `Subscription: ${subscriptionId} - Reason for cancellation: ${message}`,
            name: `${currentUser.firstName} ${currentUser.lastName}`,
            type: "contact-us",
          });
        } catch (err) {
          errors.report(err);
        }

        await axios.post("stripe/subscription/unsubscribe", {
          subscriptionId,
          uid: currentUser.uid,
        });
        flash.success(
          "Successfully unsubscribed. Your subscription will end at the end of this billing period.",
        );
      } catch (err) {
        console.log(err);
        flash.error(err.message ?? err);
        errors.report(err);
      } finally {
        if (onSubscriptionChange instanceof Function) {
          onSubscriptionChange();
        }
      }
    },
    [currentUser, onSubscriptionChange],
  );

  return (
    <>
      <ol>
        {subItems.map((sub, key) => (
          <li
            key={key}
            className="grid grid-cols-3 border-t border-[#cccccc] py-2"
          >
            <div>
              <div className="font-bold">{sub.name}</div>
              <div className={`${statusColor(sub.status)} text-sm`}>
                {sub.status}
              </div>
            </div>
            <div className="text-sm">
              <div>{sub.allowedUsers} users</div>
              <div>{sub.allowedCompanies} companies</div>
            </div>
            <div className="flex flex-row justify-end gap-2 lg:gap-3">
              <SubtleButton
                className="flex items-center gap-1"
                onClick={() => {
                  setUnsubscribeSubscriptionId(sub.id);
                  setDisplayUnsubscribeModal(true);
                }}
                color="red"
                testId="cancel-subscription"
              >
                <img src={TrashCanIcon} width="23" height="23" />
                <span className="hidden lg:inline">Cancel</span>
              </SubtleButton>
              <SubtleButton
                className="flex items-center gap-1"
                onClick={() => {
                  if (!hasPaymentMethod) {
                    alert("Please add a payment method first");
                  } else {
                    dispatch(openManageSubscriptionModal());
                  }
                }}
                testId="edit-subscription-button"
              >
                <img src={PencilIcon} width="23" height="23" />
                <span className="hidden lg:inline">Switch Plan</span>
              </SubtleButton>
            </div>
          </li>
        ))}
      </ol>
      {subItems.length < 1 ? (
        <div>
          <p className="text-description-gray my-2">
            You are not subscribed to any plan
          </p>
          <StandardButton
            onClick={() => dispatch(openManageSubscriptionModal())}
            className="max-w-[300px]"
            disabled={!hasPaymentMethod}
          >
            Choose a Plan
            {hasPaymentMethod ? null : <div>(Add Payment Method First)</div>}
          </StandardButton>
        </div>
      ) : null}
      <UnsubscribeConfirmModal
        open={displayUnsubscribeModal}
        title="Cancel Subscription"
        subtitle="Are you sure you want to unsubscribe from this subscription?  Instead of canceling you can switch to our free plan and keep your data!"
        onCancel={() => {
          setDisplayUnsubscribeModal(false);
          setUnsubscribeSubscriptionId(null);
        }}
        onEndSubscription={() => {
          setDisplayUnsubscribeModal(false);
          setDisplayUnsubscribeReasonModal(true);
        }}
      />
      <UnsubscribeReasonModal
        closeModal={() => {
          setDisplayUnsubscribeReasonModal(false);
          setUnsubscribeSubscriptionId(null);
        }}
        onUnsubscribe={async (message) => {
          await handleUnsubscribe(unsubscribeSubscriptionId, message);
          setDisplayUnsubscribeReasonModal(false);
          setUnsubscribeSubscriptionId(null);
        }}
        open={displayUnsubscribeReasonModal}
      />
    </>
  );
}
const statusColor = (status) => {
  const finishedStatus = ["Declined", "Closed", "Inactive"];
  if (status in finishedStatus || status.includes("End on")) {
    return "text-snow-red";
  }
  if (status === "Late" || status === "Pending") {
    return "text-snow-red";
  }
  if (status === "Incomplete") {
    return "text-snow-yellow";
  }
  return "text-snow-green";
};
