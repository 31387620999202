import React from "react";
import AuthLabeledTextInput from "../../components/Inputs/AuthLabeledTextInput";
import { useSearchParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import firebaseAuth from "../../services/firebase/firebaseAuth";
export default function PersonalInfoFields() {
  const [searchParams] = useSearchParams();
  const [firebaseUser, authLoading] = useAuthState(firebaseAuth.auth);

  if (authLoading) return null;

  return (
    <>
      <div className="flex flex-col sm:flex-row gap-2">
        <AuthLabeledTextInput
          label="First Name"
          maxLength={30}
          placeholder="John"
          name="first_name"
          className="my-0"
          required
        />
        <AuthLabeledTextInput
          label="Last Name"
          name="last_name"
          maxLength={20}
          placeholder="Doe"
          className="my-0"
          required
        />
      </div>
      <AuthLabeledTextInput
        label="Email Address"
        name="email"
        maxLength={200}
        placeholder="you@example.com"
        className="my-0"
        required
      />
      {firebaseUser?.phoneNumber ? (
        <input type="hidden" name="phone" value={firebaseUser.phoneNumber} />
      ) : (
        <AuthLabeledTextInput
          label="Mobile Phone Number"
          name="phone"
          maxLength={10}
          placeholder="712 555 0100"
          inputMode="tel"
          pattern="[0-9]{10}"
          required
          defaultValue={searchParams.get("phone")}
          onInput={(e) => {
            let target = e.nativeEvent.target;
            if (target.validity.patternMismatch) {
              target.setCustomValidity(
                "Please enter a US or Canadian phone number with area code. i.e. 712 555 0100",
              );
            } else {
              target.setCustomValidity("");
            }
          }}
        />
      )}
    </>
  );
}
