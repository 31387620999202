import React, { useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import firebaseAuth from "../../services/firebase/firebaseAuth";
import SplitForm from "../../components/SplitForm";
import { useState } from "react";
import ValidateCodeForm from "./ValidateCodeForm";
import axiosUsers from "../../services/axios/users";
import axios from "axios";
import errors from "../../services/errors";
import JoinForm from "./JoinForm";

const JoinPage = () => {
  const navigate = useNavigate();

  // Get the desired plan from the url if provided:
  const [searchParams] = useSearchParams();
  let selectedPlan = searchParams.get("plan");

  const [sentCode, setSentCode] = useState(false); // have we sent the customer a SMS
  const [formData, setFormData] = useState(null); // The result of the BasicInfo form
  const [formError, setFormError] = useState(null);

  const joinCompany = useCallback(
    async (joinCode, fbUser) => {
      setFormError(null);
      try {
        // We need to create a user if they don't exist:
        await axiosUsers.getOrCreateUser({
          firstName: formData.firstName,
          lastName: formData.lastName,
          phoneNumber: fbUser.phoneNumber,
          startDate: new Date().toLocaleDateString(),
          uid: fbUser.uid,
        });
        await axios.post("company/join", {
          joinCode: joinCode,
          userId: fbUser.uid,
        });
        navigate("/admin");
      } catch (err) {
        if (err.response?.status === 404) {
          setFormError("Invalid Join Code");
        } else {
          errors.report(err);
          setFormError("Error: Please contact support");
        }
      }
    },
    [navigate, formData],
  );

  const joinFormSubmit = useCallback(
    async (data) => {
      try {
        setFormData(data);
        if (data.fbUser?.uid) {
          await joinCompany(data.joinCode, data.fbUser);
        } else {
          const phoneNumber = data.phone;
          const cleanedNumber = "+1" + ("" + phoneNumber).replace(/\D/g, "");
          await firebaseAuth.sendSMSAsync(cleanedNumber);
          setSentCode(true);
        }
      } catch (err) {
        errors.report(err);
        setFormError(
          "Unable to send code - please check your phone number and try again.",
        );
      }
    },
    [joinCompany],
  );

  const validateCode = useCallback(
    async (verificationCode) => {
      let fbUser = null;
      try {
        // Send code to Firebase
        fbUser = await firebaseAuth.signInWithCodeAsync(verificationCode);
      } catch (error) {
        if (error.code === "auth/invalid-verification-code") {
          setFormError("Invalid Code - Please try again.");
        } else if (error.code === "auth/code-expired") {
          setFormError(
            'Your code has expired.  Please click "Resend Code" above and try again.',
          );
        } else {
          setFormError(error);
        }
      }
      if (fbUser) {
        // if the previous step had an error, we might not have a user
        await joinCompany(formData.joinCode, fbUser);
      }
    },
    [formData, joinCompany],
  );

  const resendCode = useCallback(async () => {
    try {
      const cleanedNumber = "+1" + ("" + formData.phone).replace(/\D/g, "");
      await firebaseAuth.sendSMSAsync(cleanedNumber);
    } catch (err) {
      errors.report(err);
      setFormError(
        "Unable to send code - please check your phone number and try again.",
      );
    }
  }, [formData]);

  return (
    <>
      <SplitForm>
        <div className="grid h-full">
          <div className="text-full-white place-self-center">
            {sentCode ? (
              <ValidateCodeForm
                phone={formData?.phone}
                resendCode={resendCode}
                handleSubmit={validateCode}
              />
            ) : (
              <JoinForm
                handleSubmit={joinFormSubmit}
                selectedPlan={selectedPlan}
              />
            )}
            {formError ? (
              <div
                className="text-snow-red p-2"
                data-testid="sign-up-page-error-message"
              >
                {formError.message ?? formError}
              </div>
            ) : null}
          </div>
        </div>
      </SplitForm>
    </>
  );
};

export default JoinPage;
