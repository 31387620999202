import React from "react";
import DragAndDropUpload from "../FileUpload/DragAndDropUpload";
import ImageNoteItem from "./ImageNoteItem";

const ImageNoteScroller = (props) => {
  // Return null if no upload or existing images
  if (props.imageArray.length === 0 && !props.enableUpload) return null;

  return (
    <div className="w-full mb-5">
      <div
        className={
          "flex overflow-x-auto space-x-3 w-full print:flex-wrap print:space-y-3"
        }
      >
        {props.imageArray.map((imageData, key) => (
          <div key={key}>
            <ImageNoteItem
              deletePressed={() => {
                const updatedArray = [...props.imageArray];

                updatedArray.splice(key, 1);
                props.setImageArray(updatedArray);
              }}
              enableDelete={props.enableDelete}
              key={key}
              uri={props.isSitemap ? imageData : imageData.uri}
              note={props.readOnly ? imageData.note : ""}
            />
            {props.readOnly ? null : (
              <textarea
                onChange={(e) => {
                  let newValue = e.target.value;
                  let newArray = [...props.imageArray];
                  newArray[key].note = newValue;
                  props.setImageArray(newArray);
                }}
                value={imageData.note}
                className="w-full border border-rounded"
                style={{ backgroundColor: "inherit", marginTop: "2px" }}
              >
                {imageData.note}
              </textarea>
            )}
          </div>
        ))}
        {props.enableUpload ? (
          <DragAndDropUpload
            imageArray={props.imageArray}
            fullWidth={false}
            onAddImages={(newImageUrls) => {
              props.setImageArray([
                ...props.imageArray,
                ...newImageUrls.map((url) => ({ uri: url, note: "" })),
              ]);
            }}
            uploadBoxLabel={props.uploadBoxLabel || "Upload"}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ImageNoteScroller;
