import React from "react";
const WithSnowFlakes = ({ children }) => {
  return (
    <>
      <div className="absolute inset-0 bg-[url('./resources/landing-page/large-snowflakes.svg')] bg-no-repeat opacity-10 z-0"></div>
      <div className="z-20 relative">{children}</div>
    </>
  );
};
export default WithSnowFlakes;
