import React from "react";
import { CoreInputStyles } from "./BasicInput";
const BasicTextArea = ({
  name,
  label,
  disabled,
  rows,
  maxLength,
  onChange,
  placeholder,
  value,
  className,
}) => {
  const input = (
    <textarea
      name={name}
      className={`${CoreInputStyles} ${className ?? ""}`}
      disabled={disabled}
      maxLength={maxLength}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      placeholder={placeholder}
      value={value}
      rows={rows}
    ></textarea>
  );
  if (label) {
    return (
      <label className={`text-sm text-full-white my-1 block`}>
        {label}
        {input}
      </label>
    );
  } else {
    return input;
  }
};

export default BasicTextArea;
