import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import StandardButton from "../../../components/Buttons/StandardButton";
import HeaderBar from "../../../components/Header/HeaderBar";
import LoadingIcon from "../../../components/Loaders/LoadingIcon";
import axiosCompanies from "../../../services/axios/companies";
import timezones from "../../../utils/timezones";
import TimeClockPage from "./TimeClockPage";
/**
 * A component to make sure the company has a timezone set before they are able to use the time clock
 */
const AdminTimeClockPageWrapper = (props) => {
  const [loading, setLoading] = useState(true);
  const [selectedTimezone, setSelectedTimezone] = useState(null);
  const [company, setCompany] = useState([]);

  useEffect(() => {
    axiosCompanies.getCompanyById(
      { companyId: props.currentUser.currentCompanyId },
      (company) => {
        setCompany(company);
        if (company.timezone) {
          setSelectedTimezone(company.timezone);
        } else {
          const browserTimezone =
            Intl.DateTimeFormat().resolvedOptions().timeZone;
          if (timezones.includes(browserTimezone)) {
            setSelectedTimezone(browserTimezone);
          }
        }
        setLoading(false);
      },
    );
    const loadData = async () => {};
    loadData();
  }, [props.currentUser.currentCompanyId]);

  const updateCompany = async () => {
    setLoading(true);
    axiosCompanies.updateCompanyById(
      {
        ...company,
        companyId: company._id,
        timezone: selectedTimezone,
      },
      (company) => {
        setCompany(company.company);
        setLoading(false);
      },
      () => {
        alert(
          "Sorry, we could not save your data right now. Please try refreshing",
        );
        setLoading(false);
      },
    );
  };

  return (
    <>
      {loading ? (
        <div className="flex flex-col items-center">
          <LoadingIcon />
        </div>
      ) : company.timezone ? (
        <TimeClockPage></TimeClockPage>
      ) : (
        <div className="flex flex-col items-center">
          <HeaderBar buttons={[]} title="Time Clock" />
          <div>
            <h2 className="text-lg mt-10 max-w-md">
              Please select a payroll timezone for your company:
            </h2>
            <div className="text-sm text-snow-primary font-light mb-1 max-w-md">
              This timezone will be used when generating reports and determining
              the start and end of pay periods. Normally this will be the
              timezone of your main office.
            </div>
            <div className="flex flex-row space-x-4 mt-5">
              <Select
                onChange={(value) => {
                  setSelectedTimezone(value.value);
                }}
                options={timezones.map((t) => {
                  return { label: t, value: t };
                })}
                placeholder="Select a Timezone"
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    borderColor: "#829399",
                    minWidth: 200,
                  }),
                }}
                value={{
                  label: selectedTimezone,
                  value: selectedTimezone,
                }}
              />
              <StandardButton
                color="green"
                disabled={false}
                label="Update Timezone"
                loading={false}
                onClick={updateCompany}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(AdminTimeClockPageWrapper);
