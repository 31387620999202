import React, { useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

// Components
import HeaderBar from "../../../components/Header/HeaderBar";
import AddPaymentMethodModal from "../../../components/Modals/AddPaymentMethodModal";
import DeactivateConfirmModal from "../../../components/Modals/DeactivateConfirmModal";
import MultipleChoiceModal from "../../../components/Modals/MultipleChoiceModal";

// Redux
import { openManageSubscriptionModal } from "../../../store/reducers/metadataSlice";

// Services
import UnsubscribeConfirmModal from "../../../components/Modals/UnsubscribeConfirmModal";
import UnsubscribeReasonModal from "../../../components/Modals/UnsubscribeReasonModal";
import axiosCompanies from "../../../services/axios/companies";
import axiosContact from "../../../services/axios/contact";
import axiosStripe from "../../../services/axios/stripe";
import axiosUsers from "../../../services/axios/users";
import ContentWrapper from "../../../components/ContentWrapper";
import PaymentMethod from "../../../components/Billing/PaymentMethod";
import Usage from "./Usage";
import Subscriptions from "../../../components/Billing/Subscriptions";
import { useQueryClient } from "@tanstack/react-query";
import StandardButton from "../../../components/Buttons/StandardButton";
import { useNavigate } from "react-router-dom";

const sectionStyle =
  "md:bg-full-white md:p-3 md:rounded-md mb-12 md:border border-[#cccccc]";
const AccountPage = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [billingInformation, setBillingInformation] = useState(null);
  const [displayAddPaymentMethodModal, setDisplayAddPaymentMethodModal] =
    useState(false);
  const [displayRemovePaymentMethodModal, setDisplayRemovePaymentMethodModal] =
    useState(false);
  const [displayUnsubscribeModal, setDisplayUnsubscribeModal] = useState(false);
  const [displayUnsubscribeReasonModal, setDisplayUnsubscribeReasonModal] =
    useState(false);
  const [unsubscirbeSubscriptionId, setUnsubscribeSubscriptionId] =
    useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const [displayConfirmAddLicenseModal, setDisplayConfirmAddLicenseModal] =
    useState(false);
  const [
    displayConfirmRemoveLicenseModal,
    setDisplayConfirmRemoveLicenseModal,
  ] = useState(false);
  const [companyToAddLicense, setCompanyToAddLicense] = useState(null);
  const [companyToRemoveLicense, setCompanyToRemoveLicense] = useState(null);
  const [nextPaymentInfo, setNextPaymentInfo] = useState(null);
  const [accountData, setAccountData] = useState(null);

  const fetchAccountData = useCallback(() => {
    setPageLoading(true);

    // Get user's account, billing, products and subscription information
    axiosUsers.getUserAccountInformationById(
      { uid: props.currentUser.uid },
      (data) => {
        // Set account information
        setBillingInformation(data.billingInformation);
        setAccountData(data);

        // Format tables for billing and subscriptions
        formatBillingTableData(
          data.billingInformation,
          data.subscriptionInformation,
        );

        setPageLoading(false);
      },
      (err) => {
        alert("Error in getting account info " + err);
      },
    );
  }, [props.currentUser.uid]);

  useEffect(() => {
    fetchAccountData();
  }, [fetchAccountData]);

  // If redirecting here from a subscription change, set customerId then refresh
  const handleSuccessfulSubscribe = useCallback(
    (sessionId) => {
      if (!sessionId) return;

      // TECH DEBT: There is an issue here where the getAuth().currentUser is null when this page first loads.  Later
      // the props change a few times and in one of those subsequent refreshes, the currentUser object is available. This
      // also means that this network request gets sent to the backend 4-15 times.  This really needs fixed, but it works for
      // now.  We work around it by checking if the currentUser is available.  Long term we should figure out why it's
      // not available on first load and find a way to await it.
      if (props.currentUser.uid) {
        axiosUsers.setStripeCustomerIdFromSessionId(
          {
            uid: props.currentUser.uid,
            sessionId,
          },
          () => {
            fetchAccountData();
          },
          (err) => {
            alert("Error in setting user.stripe.customerId " + err);
          },
        );
      }
    },
    [fetchAccountData, props.currentUser.uid],
  );

  // Handle success of upgrade
  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      const sessionId = query.get("session_id");
      handleSuccessfulSubscribe(sessionId);
    }
  }, [handleSuccessfulSubscribe]);

  // Format the Billing Information Table
  const formatBillingTableData = (billingData, subscriptionData) => {
    // If no billing information/subscription information (eg. not subscribed), don't format billing table
    if (!subscriptionData) return;

    // Format next payment date
    const currentPeriodEnd = subscriptionData[0]?.current_period_end * 1000;
    const nextPaymentDate = currentPeriodEnd
      ? new Date(currentPeriodEnd)?.toLocaleDateString()
      : "N/A";
    setNextPaymentInfo({
      date: nextPaymentDate,
      amount: billingData?.nextChargeAmount,
    });
  };

  const handleRemovePaymentMethod = () => {
    // If customerId doesn't exist, refresh data
    if (!billingInformation?.customerId) {
      setDisplayRemovePaymentMethodModal(false);
      alert(
        "Sorry about that. It seems something went wrong on our end. Please try again.",
      );

      // Refresh account data
      fetchAccountData();

      return;
    }

    axiosStripe.removePaymentMethod(
      { stripeCustomerId: billingInformation.customerId },
      () => {
        alert(
          "Successfully removed your method of payment. Your subscription will automatically be canceled at the end of this period if there is no payment method on your account.",
        );
        setDisplayRemovePaymentMethodModal(false);
        // Refresh account data
        fetchAccountData();
      },
      (err) => {
        console.error("err: ", err);
        alert(
          "Sorry about that. It seems something went wrong on our end. Please try again.",
        );

        // Refresh account data
        fetchAccountData();
      },
    );
  };

  const handleAddCompanyLicense = () => {
    if (!companyToAddLicense) return;

    axiosCompanies.addCompanyLicense(
      { companyId: companyToAddLicense, uid: props.currentUser.uid },
      () => {
        setCompanyToAddLicense(null);
        setDisplayConfirmAddLicenseModal(false);
        // Refresh account data
        fetchAccountData();
      },
      (err) => {
        setCompanyToAddLicense(null);
        setDisplayConfirmAddLicenseModal(false);
        alert(err.data.message);
        console.error("Error in handleLicenseCompany: ", err);
        // If error caused by user unsubscribed or undersubscribed, display upgrade modal
        if (err.data.needsUpgrade) {
          dispatch(openManageSubscriptionModal());
        }
      },
    );
  };
  const handleRemoveCompanyLicense = () => {
    if (!companyToRemoveLicense) return;
    axiosCompanies.removeCompanyLicense(
      { companyId: companyToRemoveLicense, uid: props.currentUser.uid },
      () => {
        setCompanyToRemoveLicense(null);
        setDisplayConfirmRemoveLicenseModal(false);
        // Refresh account data
        fetchAccountData();
      },
      (err) => {
        setCompanyToRemoveLicense(null);
        console.error("Error in handleLicenseCompany: ", err);
        alert(err);
      },
    );
  };

  const handleUnsubscribe = async (subscriptionId, message) => {
    const customer = await axiosStripe.getCustomerById({
      uid: props.currentUser.uid,
    });

    await axiosContact.sendContactUsEmail({
      email: customer.email,
      message: `Subscription: ${subscriptionId} - Reason for cancellation: ${message}`,
      name: `${props.currentUser.firstName} ${props.currentUser.lastName}`,
      type: "contact-us",
    });

    axiosStripe.removeSubscription(
      { subscriptionId, uid: props.currentUser.uid },
      () => {
        alert(
          "Successfully unsubscribed. Your subscription will end at the end of this billing period.",
        );

        // Refresh account data
        fetchAccountData();
      },
      (err) => {
        console.error("err: ", err);
        alert(
          "Sorry about that. It seems something went wrong on our end. Please try again.",
        );

        // Refresh account data
        fetchAccountData();
      },
    );
  };

  return (
    <>
      <div className="flex flex-col items-center">
        <HeaderBar title="Billing / Subscriptions" />
        <ContentWrapper loading={pageLoading}>
          {pageLoading ? null : (
            <>
              {accountData.isCustomer ? (
                <>
                  <section className={sectionStyle}>
                    <h2 className="text-2xl text-full-black font-bold">
                      Your Subscriptions
                    </h2>
                    <p className="text-description-gray max-w-[600px] mb-2">
                      If you need to pause / park your subscription until the
                      next season, just switch to our free plan and we will keep
                      all your information safe until you need it again.
                    </p>

                    {accountData == null ? null : (
                      <div className="flex flex-row gap-3 my-4 align-middle">
                        <Usage
                          name="Companies"
                          using={accountData.usage.activeCompanies}
                          total={accountData.authorizations.authorizedCompanies}
                        />
                        <Usage
                          name="Users"
                          using={accountData.usage.activeUsers}
                          total={accountData.authorizations.authorizedUsers}
                        />
                      </div>
                    )}
                    {/* Subscription Information */}
                    <Subscriptions
                      subscriptionInformation={
                        accountData.subscriptionInformation
                      }
                      productInformation={accountData.productInformation}
                      hasPaymentMethod={accountData.billingInformation != null}
                      onSubscriptionChange={() => {
                        fetchAccountData();
                        queryClient.invalidateQueries({
                          queryKey: [`user/${props.currentUser.uid}/account`],
                        });
                      }}
                      currentUser={props.currentUser}
                    />
                  </section>

                  <section className={sectionStyle}>
                    <h2 className="text-2xl text-full-black font-bold">
                      Payment Methods
                    </h2>
                    <p className="text-description-gray max-w-[600px] mb-2">
                      The following credit card will be billed each month. To
                      stop billing, switch to our free plan or cancel your
                      subscription.
                      {nextPaymentInfo ? (
                        <>
                          <br />
                          Your next payment will be charged on{" "}
                          {nextPaymentInfo.date}{" "}
                          {nextPaymentInfo.amount
                            ? `for ${nextPaymentInfo.amount}`
                            : null}
                          .
                        </>
                      ) : null}
                    </p>
                    <PaymentMethod
                      billingInfo={billingInformation}
                      currentUser={props.currentUser}
                      onChange={() => fetchAccountData()}
                      onRemoveClick={() =>
                        setDisplayRemovePaymentMethodModal(true)
                      }
                    />
                  </section>
                </>
              ) : (
                <section className={sectionStyle}>
                  <h2 className="text-2xl text-full-black font-bold">
                    Subscribe to SnowScape!
                  </h2>
                  <p className="text-description-gray">
                    Subscribe to SnowScape and enjoy the full power of our
                    platform. Once you pick your plan you will be able to create
                    your own companies and add your employees.
                  </p>
                  <StandardButton
                    className="max-w-[300px] mt-2"
                    onClick={() => navigate("/sign-up")}
                  >
                    Pick a Plan
                  </StandardButton>
                </section>
              )}
            </>
          )}
        </ContentWrapper>
      </div>
      {/* Add Payment Method Modal */}
      <AddPaymentMethodModal
        adjustForSidebar={true}
        open={displayAddPaymentMethodModal}
        onClose={() => {
          setDisplayAddPaymentMethodModal(false);
          fetchAccountData();
        }}
        title="Add Payment Method"
      />

      {/* Delete Payment Method Modal */}
      <MultipleChoiceModal
        adjustForSidebar={true}
        open={displayRemovePaymentMethodModal}
        options={[
          {
            color: "red",
            label: "Remove",
            onClick: handleRemovePaymentMethod,
          },
          {
            color: "blue",
            label: "Cancel",
            onClick: () => setDisplayRemovePaymentMethodModal(false),
          },
        ]}
        subtitle="Are you sure you want to remove this payment method?"
        title="Delete"
      />
      {/* Add License To Company Modal */}
      <MultipleChoiceModal
        adjustForSidebar={true}
        open={displayConfirmAddLicenseModal}
        options={[
          {
            color: "green",
            label: "Activate",
            onClick: handleAddCompanyLicense,
          },
          {
            color: "blue",
            label: "Cancel",
            onClick: () => setDisplayConfirmAddLicenseModal(false),
          },
        ]}
        subtitle="Are you sure you want to activate this company?"
        title="Activate Company"
      />

      {/* Remove License To Company Modal */}
      <DeactivateConfirmModal
        adjustForSidebar={true}
        open={displayConfirmRemoveLicenseModal}
        options={[
          {
            color: "red",
            label: "Deactivate",
            onClick: handleRemoveCompanyLicense,
          },
          {
            color: "blue",
            label: "I've changed my mind",
            onClick: () => setDisplayConfirmRemoveLicenseModal(false),
          },
        ]}
        title="Deactivate Company"
      />
      <UnsubscribeConfirmModal
        adjustForSidebar
        open={displayUnsubscribeModal}
        onCancel={() => {
          setDisplayUnsubscribeModal(false);
          setUnsubscribeSubscriptionId(null);
        }}
        onEndSubscription={() => {
          console.log("djm123");
          setDisplayUnsubscribeModal(false);
          setDisplayUnsubscribeReasonModal(true);
        }}
      />
      <UnsubscribeReasonModal
        adjustForSidebar
        closeModal={() => {
          setDisplayUnsubscribeReasonModal(false);
          setUnsubscribeSubscriptionId(null);
        }}
        onUnsubscribe={async (message) => {
          await handleUnsubscribe(unsubscirbeSubscriptionId, message);
          setDisplayUnsubscribeReasonModal(false);
          setUnsubscribeSubscriptionId(null);
        }}
        open={displayUnsubscribeReasonModal}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(AccountPage);
export { AccountPage };
