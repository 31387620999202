import React from "react";
import MapArrowIconBlue from "../../resources/map-arrow-blue.svg";
import MapArrowIconGreen from "../../resources/map-arrow-green.svg";

const AddressWidget = (props) => {
  const addressString =
    props.address?.streetLineOne +
    ", " +
    props.address?.city +
    ", " +
    props.address?.state +
    " " +
    props.address?.zip;

  const googleMapsLink =
    "http://maps.google.com/maps?q=" + addressString.replace(/ /g, "+");

  return (
    <div
      className={`flex flex-row self-start hover:opacity-70 active:opacity-40 ${props.className ?? ""}`}
      onClick={() => window.open(googleMapsLink, "_blank")}
    >
      <div className="pr-3 self-center justify-center">
        <img
          alt="map-arrow"
          className={props.small ? "w-3 h-3" : "w-5 h-5"}
          src={props.season === "snow" ? MapArrowIconBlue : MapArrowIconGreen}
        />
      </div>
      <div className="flex flex-col font-snow-secondary">
        <div className={props.small ? "text-xs" : ""}>
          {props.address.streetLineOne}
        </div>
        <div className={props.small ? "text-xs" : ""}>
          {props.address.streetLineTwo}
        </div>
        <div className={props.small ? "text-xs" : ""}>
          {props.address.city}, {props.address.state} {props.address.zip}
        </div>
      </div>
    </div>
  );
};

export default AddressWidget;
