import React from "react";
import RoundButton from "../Buttons/RoundButton";
import ModalWrapper from "./ModalWrapper";

const DeactivateConfirmModal = (props) => {
  return (
    <>
      {props.open ? (
        <ModalWrapper clickOff={props.closeModal}>
          <div
            className={`flex flex-col w-96 rounded-xl bg-snow-white drop-shadow-2xl px-12 pt-12 pb-6 overflow-x-scroll ${
              props.adjustForSidebar ? "ml-60" : null
            }`}
            data-testid="deactivate-confirm-modal"
          >
            <div className="flex flex-row justify-between w-full">
              <div className="text-2xl font-bold font-snow-primary">
                {props.title}
              </div>
            </div>
            <div className="text-base font-light mb-4">
              Are you sure you want to Deactivate this company? You and other
              users will not be able to access this company's data until it's
              been activated again. You can reactivate it at anytime.
            </div>
            <div className="text-base font-semibold mb-4">
              This action will not cancel your account and your bill will remain
              unchanged.
            </div>
            <div className="text-base font-light mb-4">
              To cancel your account please go to the{" "}
              <a
                className="text-snow-secondary underline"
                href="/admin/account"
              >
                Account
              </a>{" "}
              page.
            </div>

            <div className="flex flex-col flex-1 w-full">
              {props.options.map((option, key) => (
                <div className="mb-2" key={key}>
                  <RoundButton
                    color={option.color || "blue"}
                    fullWidth
                    label={option.label}
                    loading={null}
                    onClick={option.onClick}
                  />
                </div>
              ))}
            </div>
          </div>
        </ModalWrapper>
      ) : null}
    </>
  );
};

export default DeactivateConfirmModal;
