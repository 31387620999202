import React, { useState } from "react";
import PulseLoader from "react-spinners/PulseLoader";

const DefaultButtonStyles =
  "text-full-white text-center max-w-[500px] w-full rounded-md py-3 bg-gradient-to-r from-[rgba(5,103,203,1)] to-[rgba(1,171,234,1)] disabled:from-[rgba(40,45,53,1)] disabled:to-[rgba(40,45,53,1)] disabled:opacity-50 disabled:border-solid disabled:border disabled:border-[#50565F] outline-none";

function SquareButton({
  onClick,
  disabled,
  loading,
  loaderColor,
  loaderSize,
  label,
  children,
  className,
  testId,
  type,
}) {
  const [onClickRunning, setOnClickRunning] = useState(false);
  return (
    <button
      onClick={async (e) => {
        if (typeof onClick === "function") {
          if (!loading && !onClickRunning) {
            setOnClickRunning(true);
            try {
              await onClick(e);
            } finally {
              setOnClickRunning(false);
            }
          }
        }
      }}
      disabled={disabled}
      className={`${DefaultButtonStyles} ${className ?? ""}`}
      data-testid={testId}
      type={type ?? "submit"}
    >
      {loading || onClickRunning ? (
        <PulseLoader
          color={loaderColor || "#fffafa"}
          size={loaderSize || 5}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        label ?? children
      )}
    </button>
  );
}

export default SquareButton;
export { DefaultButtonStyles };
