import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// Components
import ActiveRouteCard from "../../../components/Cards/ActiveRouteCard";
import StartARouteCard from "../../../components/Cards/StartARouteCard";
import HeaderBar from "../../../components/Header/HeaderBar";
import LoadingIcon from "../../../components/Loaders/LoadingIcon";
import { useQuery, useQueryClient } from "@tanstack/react-query";

const ActiveRoutesPage = (props) => {
  const queryClient = useQueryClient();

  const activeRoutesQuery = useQuery({
    queryKey: ["company", props.currentUser.currentCompanyId, "activeRoutes"],
    queryFn: async () =>
      (
        await axios.get(
          `activeRoute/company/${props.currentUser.currentCompanyId}?take=10000`,
        )
      ).data,
  });

  const loading = activeRoutesQuery.isLoading;
  const activeRoutes = activeRoutesQuery.data;

  const refreshData = () => {
    queryClient.invalidateQueries({
      queryKey: ["company", props.currentUser.currentCompanyId, "activeRoutes"],
    });
  };

  return (
    <div className="flex flex-col items-center">
      <HeaderBar
        title="Active Routes"
        button={{
          color: "blue",
          enabled: true,
          label: "Refresh Data",
          onClick: () => refreshData(),
        }}
      />
      <div className="items-start w-full px-10 pt-3">
        <p>
          See which routes and properties your staff are currently servicing.
        </p>
      </div>
      {loading ? (
        <LoadingIcon />
      ) : (
        <div className="flex flex-1 flex-col w-full p-10 items-center overflow-y-scroll">
          {/* Render all Landscape Routes */}
          <div className="w-full">
            {activeRoutes.map((route, key) => (
              <ActiveRouteCard
                route={route}
                key={key}
                onDelete={refreshData}
              ></ActiveRouteCard>
            ))}
          </div>
        </div>
      )}
      <div className="flex items-start w-full px-10">
        <StartARouteCard></StartARouteCard>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(ActiveRoutesPage);
