import React from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";

// Components
import LandingNavBar from "../../components/Navigation/LandingNavBar";
import Footer from "../../components/LandingPage/Footer";

function LandingPage() {
  return (
    <>
      <LandingNavBar />
      <div
        style={{
          display: "grid",
          gridTemplateRows: "80px 1fr auto",
          minHeight: "100vh",
        }}
      >
        <div>header</div>
        <div className="text-snow-white content-wrapper bg-gradient-to-br from-landing-page-gradient-start to-landing-page-black">
          <Outlet />
        </div>
        <div>
          <Footer />
        </div>
      </div>
      <ScrollRestoration />
    </>
  );
}
export default LandingPage;
