import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";

// Resources
import HamburgerIcon from "../../resources/hamburger-white.svg";
import SnowScapeLogoWithWhiteText from "../../resources/logo/snowscape-logo-horizontal-with-white-text.svg";
import Button from "../LandingPage/Button";
import ButtonLink from "../Buttons/ButtonLink";

const LandingNavBar = (props) => {
  const location = useLocation();

  const [displayMobileMenu, setDisplayMobileMenu] = useState(false);

  const navItems = [
    { label: "Home", url: "/" },
    { label: "About", url: "/about" },
    { label: "Pricing", url: "/pricing" },
    { label: "Features", url: "/features" },
    { label: "Tutorials / Demos", url: "/docs" },
    { label: "FAQ", url: "/faq" },
    { label: "Contact", url: "/contact" },

    // Conditionally pass in trial period if new user
    ...(!props.currentUser?.uid
      ? [
          {
            label: "Login",
            url: "/admin-login",
            spaceBefore: true,
          },
        ]
      : [
          {
            label: "Admin Dashboard",
            url: "/admin",
            spaceBefore: true,
          },
        ]),
  ];

  const handleHamburgerClicked = () => {
    setDisplayMobileMenu(!displayMobileMenu);
  };

  return (
    <nav className="w-full fixed h-20 bg-landing-page-black 2xl:px-20 px-4 flex z-40 drop-shadow-2xl">
      <div className="flex justify-between w-full max-w-[2000px] m-auto">
        {/* Snowscape Logo */}
        <Link to="/" className="flex">
          <img
            alt="snowscape-logo"
            className="my-auto"
            src={SnowScapeLogoWithWhiteText}
          />
          {/* <img
                        alt='snowscape-logo'
                        className='w-12 my-auto'
                        src={SnowScapeLogo}
                    />
                    <p className='ml-3 my-auto text-3xl font-light text-snow-white'>
                        SnowScape
                    </p> */}
        </Link>

        {/* Mobile Hamburger Menu */}
        <div
          className="self-center cursor-pointer lg:hidden"
          onClick={handleHamburgerClicked}
        >
          <img alt="hamburger-menu" className="" src={HamburgerIcon} />
        </div>
        {/* Mobile Dropdown Menu */}
        <ul
          className={`fixed lg:hidden w-full left-0 top-20 bg-snow-white float-right text-center transition-all duration-700 ${
            displayMobileMenu
              ? "h-screen overflow-scroll"
              : "h-0 overflow-hidden"
          }`}
        >
          {navItems.map((item, key) => (
            <li
              className={`leading-8 m-10 transition-all text-xl uppercase select-none font-bold  
                            ${item.spaceBefore ? "pt-10 border-t-[1px]" : ""}`}
              key={key}
            >
              <Link
                className={`mx-3 hover:opacity-50 ${
                  location.pathname === item.url
                    ? "text-snow-green"
                    : "text-snow-primary"
                }`}
                onClick={handleHamburgerClicked}
                to={item.url}
              >
                {item.label}
              </Link>
            </li>
          ))}
          <>
            {props.currentUser?.uid ? null : (
              <li
                className={`leading-8 m-10 transition-all text-xl uppercase select-none font-bold text-snow-white`}
              >
                <ButtonLink
                  to="/sign-up"
                  className="w-auto px-3 pt-2 pb-2 mx-auto"
                  onClick={handleHamburgerClicked}
                >
                  Get Started
                </ButtonLink>
              </li>
            )}
          </>
        </ul>
        {/* Desktop Menu */}
        <div className="hidden lg:flex flex-row justify-between my-auto font-bold text-snow-white items-center">
          {navItems.map((item, key) => (
            <Link
              className={`xl:mx-4 mx-2 hover:underline ${
                location.pathname === item.url ? "" : ""
              } ${item.spaceBefore ? "ml-3 pl-5 border-l-[1px]" : ""} ${item.label === "Home" ? "hidden xl:inline" : ""}`}
              key={key}
              to={item.url}
            >
              {item.label}
            </Link>
          ))}
          {props.currentUser?.uid ? null : (
            <ButtonLink to="/sign-up" className="w-auto px-3 pt-2 pb-2">
              Get Started
            </ButtonLink>
          )}
        </div>
      </div>
    </nav>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(LandingNavBar);
