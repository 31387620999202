import React from "react";
const CoreInputStyles =
  "w-full text-base mt-1 mb-1 text-full-white font-light bg-[#313945] bg-no-repeat bg-[7px_center] p-3 border-solid border border-[#50565F] outline-none rounded-md focus:border-[#0B6BAC] disabled:opacity-50";

const BasicInput = ({
  name,
  label,
  disabled,
  type,
  maxLength,
  minLength,
  onChange,
  placeholder,
  value,
  pattern,
  required,
  className,
  onInput,
  testId,
  defaultValue,
  autoFocus,
}) => {
  const input = (
    <input
      name={name}
      className={`${CoreInputStyles} ${className ?? ""} basic-input`}
      disabled={disabled}
      type={type || "text"}
      maxLength={maxLength}
      minLength={minLength}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      placeholder={placeholder}
      value={value}
      pattern={pattern}
      required={required ?? false}
      onInput={onInput}
      data-testid={testId}
      defaultValue={defaultValue}
      autoFocus={autoFocus === true}
    />
  );
  if (label) {
    return (
      <label className={`w-full text-sm text-full-white my-1 block`}>
        {label}
        {input}
      </label>
    );
  } else {
    return input;
  }
};

export default BasicInput;
export { CoreInputStyles };
