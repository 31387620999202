import React from "react";
import RadioOption from "../../components/Inputs/RadioOption";
import { PricingData } from "../../components/Pricing";

const ChoosePlanInput = ({ name, onPlanChange }) => {
  return (
    <div data-testid="choose-plan-input">
      {PricingData.map((plan) => (
        <RadioOption
          key={plan.id}
          name={name ?? "plan"}
          value={plan.id}
          defaultChecked={plan.default ?? false}
          onChange={onPlanChange}
        >
          <div className="inline-grid grid-rows-2 lg:grid-rows-1 lg:grid-cols-2 lg:gap-2 w-[350px]">
            <span className="flex gap-2">
              <span className="">{plan.name}</span>
              <span>${plan.price} / month</span>
            </span>
            <span className="">
              {plan.companies} {plan.companies > 1 ? "companies" : "company"},{" "}
              {plan.users} {plan.users > 1 ? "users" : "user"}
            </span>
          </div>
        </RadioOption>
      ))}
    </div>
  );
};

export default ChoosePlanInput;
