import React, { useMemo, useState } from "react";
import HeaderBar from "../../components/Header/HeaderBar";
import LabeledTextboxInput from "../../components/Inputs/LabeledTextboxInput";
import axiosUsers from "../../services/axios/users";
import ContentWrapper from "../../components/ContentWrapper";
import axios from "axios";
import { connect } from "react-redux";
function ProfileSettingsPage({ currentUser }) {
  const [accountInformation, setAccountInformation] = useState(null);
  const [loading, setLoading] = useState(true);
  // const accountQuery = useQuery({ queryKey: `user/${currentUser.uid}/account` });

  useMemo(async () => {
    try {
      const res = await axios.get(`user/${currentUser.uid}/account`);
      setAccountInformation(res.data.accountInformation);
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  const handleSaveClicked = async () => {
    axiosUsers.updateUserById(
      { ...accountInformation, uid: currentUser.uid },
      () => {
        // navigation.navigate("/admin/route");
      },
      (err) => {
        console.error(err);
      },
    );
  };
  return (
    <div>
      <HeaderBar
        title="Profile Settings"
        buttons={[
          {
            color: "green",
            enabled: true,
            label: "Save",
            onClick: handleSaveClicked,
          },
        ]}
      />
      <ContentWrapper>
        <LabeledTextboxInput
          loading={loading}
          label="First Name"
          maxLength={30}
          placeholder="John"
          setValue={(val) => {
            setAccountInformation({
              ...accountInformation,
              firstName: val,
            });
          }}
          value={accountInformation?.firstName}
        />

        <LabeledTextboxInput
          loading={loading}
          label="Last Name"
          maxLength={30}
          placeholder="Doe"
          setValue={(val) => {
            setAccountInformation({
              ...accountInformation,
              lastName: val,
            });
          }}
          value={accountInformation?.lastName}
        />
        <LabeledTextboxInput
          loading={loading}
          disabled={true}
          label="Phone Number"
          placeholder="+1(***) *** ****"
          setValue={null}
          value={accountInformation?.phoneNumber}
        />
        <LabeledTextboxInput
          loading={loading}
          label="Email"
          maxLength={50}
          placeholder="email@address.com"
          setValue={(val) => {
            setAccountInformation({
              ...accountInformation,
              email: val,
            });
          }}
          value={accountInformation?.email}
        />
      </ContentWrapper>
    </div>
  );
}

export default connect((state) => ({ currentUser: state.currentUser }))(
  ProfileSettingsPage,
);
export { ProfileSettingsPage };
