import React from "react";

const VideoCard = ({ url, icon: Icon, title, children, className }) => {
  return (
    // <div className="bg-gradient-to-b from-[#10222F] -mx-8 lg:mx-0 p-8 rounded-lg">
    // <div className="lg:bg-gradient-to-b lg:from-[#152c3d] bg-[#152c3d] lg:bg-transparent -mx-8 lg:mx-0 p-8 rounded-lg">
    <div
      className={`bg-gradient-to-b from-[#152c3d] -mx-6 lg:mx-0 p-8 rounded-lg ${className ?? ""}`}
    >
      <div className="flex items-center flex-col sm:flex-row">
        <div className="w-1/3 flex flex-col items-center justify-between">
          <div className="bg-[#2b3942] p-8 rounded-xl hidden sm:block">
            <img src={Icon} alt={title} className="w-8 h-8" />
          </div>
          <div className="sm:mt-4 mb-4 sm:mb-0">
            <h2 className="text-xl font-bold text-snow-white">{title}</h2>
          </div>
        </div>
        <div className="w-full sm:w-2/3">
          <iframe
            className="w-full h-full min-h-[300px] lg:min-h-0 rounded-xl"
            src={url}
            title="YouTube Video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <div className="mt-4">
        <h3 className="text-snow-white font-light">{children}</h3>
      </div>
    </div>
  );
};

export default VideoCard;
