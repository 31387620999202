import React, { useState } from "react";

// Resources
import InputWithIcon from "../../components/Filters/InputWithIcon";
import WithSnowFlakes from "../../components/LandingPage/WithSnowFlakes";
import ModalWrapper from "../../components/Modals/ModalWrapper";

const DocsPage = () => {
  // title and their descriptions
  const desktopTutorialsDemosList = [
    {
      title: "SnowScape Desktop Admin Tutorial #1",
      description: "",
      url: "https://www.youtube.com/embed/7QRndkzgqDk",
      type: "desktop",
    },
    {
      title:
        "SnowScape DesktopTutorial: Using and Managing your Routes Number (Video #2)",
      description: "",
      url: "https://www.youtube.com/embed/S9FgcUcvvpA",
      type: "desktop",
    },
    {
      title:
        "SnowScape Desktop Tutorial: Properties Page and Setting Up Properties on the Desktop (Video #3)",
      description: "",
      url: "https://www.youtube.com/embed/azTEzckYJJg",
      type: "desktop",
    },
    {
      title: "SnowScape Desktop tutorial Viewing Property History Video 4",
      description:
        "Viewing property history is a breeze with SnowScape and is one of the main reasons we decided to build out an app of our own. This is where the proof of all work completed is. Pictures, data, weather at time of service and service notes.  It is easily filterable and searchable by property and date range.",
      url: "https://www.youtube.com/embed/hFTCjto26-Q",
      type: "desktop",
    },
    {
      title:
        "SnowScape Desktop Tutorial Managing Services and Adding Services Video 5",
      description:
        "Adding Services- this app is catered toward Snow and Lawn/Landscape Companies. But any service based industry can be used with this app. You can create and delete different services to customize your companies service offering on each property you manage and service.",
      url: "https://www.youtube.com/embed/KGQutpmayms",
      type: "desktop",
    },
    {
      title:
        "SnowScape Desktop Tutorial: Managing Materials and Editing and add Materials (Video #6)",
      description:
        'This tutorial video is showing how you can manage materials on the Snowscape Desktop @ mysnowscape.com. You can add, edit and remove materials within the materials page. Customizable for any service based company. This also allows you to change the units. For example "Tons" of salt vs "Yards" of salt. Every company is different and this allows you to customize it within the app.',
      url: "https://www.youtube.com/embed/QOYS-9JMUHc",
      type: "desktop",
    },
    {
      title: "SnowScape Desktop Tutorial Creating a Report Video 7",
      description:
        "This video tutorial is showing how to produce reports on the SnowScape Desktop. Showing reports for all properties for the whole season, looking at your total man hours, total materials, for the year, for the week, or for just one stop/visit. Its Ian invaluable resource to dial in your numbers for what you used on each property, and increase profits by adjusting numbers to become more profitable",
      url: "https://www.youtube.com/embed/sTCarefQAb4",
      type: "desktop",
    },
    {
      title: "SnowScape Desktop Tutorial: Creating a Route (Video 8)",
      description:
        "This video shows how easy it is to create a route and organize the route in order the you would like to have properties in. You can add and remove properties, change the order, and label the route customized for each foreman or crew.",
      url: "https://www.youtube.com/embed/iWSy-IS3KnQ",
      type: "desktop",
    },
  ];
  const mobileTutorialsDemosList = [
    {
      title: "SnowScape Login Tutorial #1",
      description:
        "Welcome to SnowScape. If you have questions regarding how to login, how to select a subscription, which subscription is best for you, and how to add a coupon code or pay for a subscription, this video is for you!",
      url: "https://www.youtube.com/embed/p5Gk0Uoh3gc",
      type: "mobile",
    },
    {
      title: "SnowScape Tutorial #2",
      description:
        "This video explains how to select a season and get the app set up for your personal company.",
      url: "https://www.youtube.com/embed/wgobUMaRf6o",
      type: "mobile",
    },
    {
      title:
        "Snowscape Tutorial: Creating a Route and Property with the SnowScape App #3",
      description:
        "This is a tutorial video showing how to create a route and create a property within the SnowScape App! mysnowscape.com",
      url: "https://www.youtube.com/embed/kTKX4yQDhQI",
      type: "mobile",
    },
    {
      title: "SnowScape Tutorial: Using a route as an employee or user",
      description:
        "This is a tutorial video to show end users, the people in the field that are going to be performing services and logging data within the app. This video shows how to click through routes and different ways to flag and report emergencies on the property.",
      url: "https://www.youtube.com/embed/iFlh10GHHeU",
      type: "mobile",
    },
    {
      title:
        "SnowScape Tutorial: Admin features and backend reporting and job History",
      description:
        "This video shows how Snowscape is an invaluable tool for the owners/operators of a snow removal or service based company. This shows the backend of the Admin side of the app. Showing how you can view job history, routes in progress, changes and alter routes and the route order, as well as create properties and manage employees",
      url: "https://www.youtube.com/embed/1fFN8xUnp48",
      type: "mobile",
    },
    {
      title: "SnowScape Tutorial: Settings page and Overall Synopsis",
      description: "",
      url: "https://www.youtube.com/embed/wS-vVnvlOb0",
      type: "mobile",
    },
  ];
  const desktopHowToList = [
    {
      title:
        "SnowScape: How to Video # 11 (Navigating your account on the desktop version)",
      description: "",
      url: "https://www.youtube.com/embed/tT3DVdiBnyM",
      type: "desktop",
    },
    {
      title:
        "SnowScape: How To Video #12 (Creating a route on the desktop version)",
      description: "",
      url: "https://www.youtube.com/embed/k4cUaL6gFeA",
      type: "desktop",
    },
    {
      title:
        "SnowScape: How to Video #14 (Editing a property on the Desktop Version)",
      description: "",
      url: "https://www.youtube.com/embed/i4h-6vFMqIY",
      type: "desktop",
    },
    {
      title:
        "SnowScape: How To Video #13 (Creating a Property on Desktop Version)",
      description: "",
      url: "https://www.youtube.com/embed/7WONs9NmhvM",
      type: "desktop",
    },
    {
      title:
        "SnowScape: How To Video #15 (Viewing property history on the Desktop Version)",
      description: "",
      url: "https://www.youtube.com/embed/HNxQPYVSlpE",
      type: "desktop",
    },
    {
      title:
        "SnowScape: How to Video #16 (Add a property when route is in progress)",
      description:
        "We call this the emergency audible. In the snow removal industry this could happen a lot. On the desktop version, this is how you can call an audible and change or add the property list for your drivers and employees ",
      url: "https://www.youtube.com/embed/HNxQPYVSlpE",
      type: "desktop",
    },
  ];
  const mobileHowToList = [
    {
      title:
        "SnowScape: How To Video #2: Checking the status of a route in progress or end early",
      description: "mysnowscape.com",
      url: "https://www.youtube.com/embed/YaHY4uvKfEw",
      type: "mobile",
    },
    {
      title: "SnowScape: How to video #3: Logging a route in the App",
      description: "mysnowscape.com",
      url: "https://www.youtube.com/embed/oHJV6dH6Vmc",
      type: "mobile",
    },
    {
      title: "SnowScape: How to video #3: Logging a route in the App",
      description: "mysnowscape.com",
      url: "https://www.youtube.com/embed/oHJV6dH6Vmc",
      type: "mobile",
    },
    {
      title: "SnowScape: How To Video #5: Going out of order on a route",
      description: "mysnowscape.com",
      url: "https://www.youtube.com/embed/xgkmd0BqKEY",
      type: "mobile",
    },
    {
      title: " SnowScape: How To Video #6: Viewing Job History on App ",
      description: "mysnowscape.com",
      url: "https://www.youtube.com/embed/qJ5PodvIdwU",
      type: "mobile",
    },
    {
      title: " SnowScape: How To Video #7 (Managing Routes on the App) ",
      description: "mysnowscape.com",
      url: "https://www.youtube.com/embed/1RXEvCvR0Nk",
      type: "mobile",
    },
    {
      title: " SnowScape App How To Video #8: How to create routes ",
      description: "mysnowscape.com",
      url: "https://www.youtube.com/embed/csGee8CgChY",
      type: "mobile",
    },
    {
      title: " SnowScape: How To Video #9 (Creating Properties on App) ",
      description: "mysnowscape.com",
      url: "https://www.youtube.com/embed/Uo041CWm4JY",
      type: "mobile",
    },
  ];

  const [desktopTutorialsDemos, setDesktopTutorialsDemos] = useState(
    desktopTutorialsDemosList,
  );
  const [mobileTutorialsDemos, setMobileTutorialsDemos] = useState(
    mobileTutorialsDemosList,
  );
  const [desktopHowTo, setDesktopHowTo] = useState(desktopHowToList);
  const [mobileHowTo, setMobileHowTo] = useState(mobileHowToList);
  const [searchString, setSearchString] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [displayVideo, setDisplayVideo] = useState(null);

  const handleSearching = (searchString) => {
    setSearchString(searchString);

    let searchFuc = (item) =>
      item.title
        ?.toLocaleLowerCase()
        .includes(searchString.toLocaleLowerCase()) ||
      item.description
        ?.toLocaleLowerCase()
        .includes(searchString.toLocaleLowerCase()) ||
      item.type?.toLocaleLowerCase().includes(searchString.toLocaleLowerCase());

    setDesktopTutorialsDemos(desktopTutorialsDemosList.filter(searchFuc));
    setMobileTutorialsDemos(mobileTutorialsDemosList.filter(searchFuc));
    setDesktopHowTo(desktopHowToList.filter(searchFuc));
    setMobileHowTo(mobileHowToList.filter(searchFuc));
  };

  const videoList = [
    {
      title: "Desktop App Tutorials",
      list: desktopTutorialsDemos,
    },
    {
      title: "Mobile App Tutorials",
      list: mobileTutorialsDemos,
    },
    {
      title: "Desktop App How To",
      list: desktopHowTo,
    },
    {
      title: "Mobile App How To",
      list: mobileHowTo,
    },
  ];

  return (
    <WithSnowFlakes>
      <div className="flex flex-col w-full items-center px-4 py-5 overflow-scroll ">
        <div className="w-full ">
          {/* Search Bar */}
          <div className="grid grid-cols-1 lg:grid-cols-2">
            <h1 className="font-medium text-4xl">Tutorials & Demos</h1>
            <SearchBar handleSearching={handleSearching} value={searchString} />
          </div>
          {/* video section */}
          {videoList.map((item, key) => (
            <SectionYoutube
              title={item.title}
              list={item.list}
              key={key}
              showVideo={(url) => {
                setShowPopup(true);
                setDisplayVideo(url);
              }}
            />
          ))}
        </div>
        {showPopup ? (
          <ModalWrapper
            clickOff={() => {
              setShowPopup(false);
            }}
          >
            <div className="flex flex-col w-[90%] lg:w-2/3 rounded-xl bg-snow-white drop-shadow-2xl px-1 lg:px-5 py-1 lg:py-5 overflow-x-scroll">
              <iframe
                className="rounded-xl mx-auto"
                style={{
                  aspectRatio: "16/9",
                  maxWidth: "100%",
                  width: "1500px",
                }}
                src={displayVideo + "?autoplay=1"}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;fullscreen"
              ></iframe>
            </div>
          </ModalWrapper>
        ) : // <div className="fixed top-[50px] left-[50px] right-[50px] bg-snow-red p-20">
        //   <iframe
        //     className="rounded-xl mx-auto"
        //     style={{ aspectRatio: "16/9", maxWidth: "100%", width: "1500px" }}
        //     src={displayVideo}
        //     frameBorder="0"
        //     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;fullscreen"
        //   ></iframe>
        // </div>
        null}
      </div>
    </WithSnowFlakes>
  );
};

const SectionYoutube = (props) => {
  return (
    <div className="mb-[75px]">
      <div className="text-snow-white font-medium text-2xl mt-5 text-left border-b pb-4">
        {props.title}
      </div>

      {props.list.length === 0 ? (
        <div className="mt-7 text-snow-white text-left font-light text-2xl">
          No tutorials match your search
        </div>
      ) : null}

      <div className="mt-7 text-snow-white text-left grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-5 justify-center  p-1 gap-3">
        {props.list.map((item, key) => (
          <YouTubeTutorial
            url={item.url}
            key={key}
            description={item.description}
            title={item.title}
            showVideo={props.showVideo}
          />
        ))}
      </div>
    </div>
  );
};

function getThumbnailImage(youTubeUrl) {
  //https://www.youtube.com/embed/iFlh10GHHeU
  try {
    // const id = youTubeUrl.match(/\/([^/]+)$/)[1];
    const id = youTubeUrl.split("/").pop();
    return `https://img.youtube.com/vi/${id}/hqdefault.jpg`;
  } catch (err) {
    console.log(youTubeUrl);
    return null;
  }
}
const YouTubeTutorial = (props) => {
  return (
    <div
      className="cursor-pointer overflow-hidden shadow-lg max-w-[480px] bg-gradient-to-t p-4 to-[#435561] from-[#152c3d] rounded-lg shadow-lg text-left"
      onClick={() => {
        props.showVideo(props.url);
      }}
    >
      <div className=" aspect-[4/3]  relative">
        <img
          src={getThumbnailImage(props.url)}
          loading="lazy"
          className="object-cover"
        />
        <svg
          width="80"
          height="64"
          version="1.1"
          viewBox="0 0 132.29 105.83"
          className="aspect-[10/8] max-w-[40%] absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 text-[#3dab14]"
        >
          <g transform="translate(0 -191.17)">
            <g transform="matrix(.97702 0 0 .97702 -36.441 98.996)">
              <rect
                x="39.118"
                y="95.046"
                width="131.76"
                height="106.91"
                ry="13.697"
                fill="currentColor"
              />
              <path
                d="m87.569 124.71 46.505 22.451-45.703 25.123z"
                fill="#fff"
                stroke="#fff"
                strokeLinejoin="round"
                strokeWidth="11.665"
              />
            </g>
          </g>
        </svg>
      </div>
      <div className="">
        <h3 className="mt-2 text-snow-white font-medium text-lg lg:text-xl  md:text-left  mb-2 ">
          {props.title}
        </h3>
        <p className="text-snow-white font-small text-sm md:text-left">
          {props.description}
        </p>
      </div>
    </div>
  );
};
// const YouTubeTutorial2 = (props) => {
//   return (
//     <div className="overflow-hidden shadow-lg max-w-[480px]" onClick={() => {props.showVideo(props.url)}}>
//       <div className=" aspect-[4/3]  relative">
//         <img src={getThumbnailImage(props.url)} loading="lazy" className="object-cover" />
//         <svg width="100" height="80" version="1.1" viewBox="0 0 132.29 105.83" className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 text-[#3dab14]">
//           <g transform="translate(0 -191.17)">
//           <g transform="matrix(.97702 0 0 .97702 -36.441 98.996)">
//           <rect x="39.118" y="95.046" width="131.76" height="106.91" ry="13.697" fill="currentColor"/>
//           <path d="m87.569 124.71 46.505 22.451-45.703 25.123z" fill="#fff" stroke="#fff" strokeLinejoin="round" strokeWidth="11.665"/>
//           </g>
//           </g>
//         </svg>
//       </div>
//       <div className="px-6 py-4">
//         <h3 className="mt-2 text-snow-white font-medium text-xl text-center md:text-left  mb-2 ">
//           {props.title}
//         </h3>
//         <p className="text-snow-white font-small text-1xl text-center md:text-left">
//           {props.description}
//         </p>
//       </div>
//     </div>
//   );
// };

const SearchBar = (props) => {
  return (
    <InputWithIcon
      onChange={(value) => props.handleSearching(value)}
      disabled={props.disabled}
      placeholder="Search..."
      value={props.value}
    />
  );
};

export default DocsPage;
