import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { CardButton } from "./PaymentMethod";
import { submitPaymentMethodToStripe } from "../Stripe/AddPaymentMethodForm";
import flash from "../../services/flash";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    theme: "stripe",
    base: {
      iconColor: "#ffffff",
      color: "#ffffff",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "14px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#ffffff" },
      "::placeholder": { color: "#ffffff" },
    },
    invalid: {
      iconColor: "#B73827",
      color: "#B73827",
    },
  },
};

export default function CardForm({ currentUser, onSuccess }) {
  const stripe = useStripe();
  const elements = useElements();
  const [submitLoading, setSubmitLoading] = useState(false);

  const save = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    try {
      const formData = new FormData(e.target);
      const formProps = Object.fromEntries(formData);
      await submitPaymentMethodToStripe(
        stripe,
        elements,
        formProps.card_name,
        currentUser,
      );
    } catch (e) {
      let message =
        e.message ?? "Unable to save your card - please check your details";
      flash.error(message);
      console.log(e);
    } finally {
      setSubmitLoading(false);
    }
    if (onSuccess) {
      onSuccess();
    }
  };

  return (
    <form className="grid grid-rows-4 h-full font-roboto" onSubmit={save}>
      <h2 className="text-2xl text-full-white ">Add Credit Card</h2>
      <input
        name="card_name"
        placeholder="Name on Card..."
        className="text-sm py-2 px-2 font-semibold bg-transparent border border-full-white rounded-md self-center outline-none text-full-white placeholder-full-white"
      />
      <div className="border border-full-white p-2 rounded-md self-center">
        <CardElement
          className="w-full text-full-white"
          id="card-element"
          options={CARD_OPTIONS}
        />
      </div>
      <CardButton className="self-center" loading={submitLoading}>
        Save
      </CardButton>
    </form>
  );
}
