import React from "react";

import GearIcon from "../../resources/landing-page/gear.svg";
import MergeIcon from "../../resources/landing-page/merge.svg";
import ShieldIcon from "../../resources/landing-page/shield.svg";
import VideoCard from "./components/VideoCard";
import DesktopMobileApp from "../../resources/landing-page/desktop-mobile-combined.png";

import LandingPageModal from "./components/LandingPageModal";
import MaxWidth from "./components/MaxWidth";
import ButtonLink from "../../components/Buttons/ButtonLink";
import AppLinks from "./components/AppLinks";

const HomePage = () => {
  const [isPopupOpen, setIsPopupOpen] = React.useState(false);
  const [videoUrl, setVideoUrl] = React.useState(null);

  const handlePopupClick = (e) => {
    e.preventDefault();
    setIsPopupOpen(true);
  };

  return (
    <div className="flex flex-col">
      <div className="h-full bg-full-black/20 bg-[url('./resources/landing-page/landing-page-01.jpg')] bg-cover p-4 lg:p-8 relative bg-blend-darken">
        <div className="max-w-[2000px] m-auto flex flex-col lg:flex-row lg:mb-10">
          <div className="lg:w-1/3 flex flex-col">
            <h1 className="text-2xl lg:text-5xl text-snow-white font-bold leading-tight text-center lg:text-left mt-10 lg:mt-0">
              The Perfect Solution for Landscape Business Owners
            </h1>
            <hr className="text-full-white w-full border-t-1 hidden lg:block mt-2" />
            <h2
              className="lg:text-xl text-snow-white mt-4 uppercase text-center lg:text-left lg:font-bold"
              style={{ lineHeight: "1.5rem" }}
            >
              Software created to protect, manage, and streamline your
              operations
            </h2>
            <h3
              className="text-xl text-snow-white mt-4 text-center lg:text-left hidden lg:block text-sm"
              style={{ lineHeight: "1.5rem" }}
            >
              Create Routes, Organize Operations, and Grow Your Business.
            </h3>
            <img
              className="lg:hidden mt-4"
              src={DesktopMobileApp}
              loading="lazy"
            />
            <ButtonLink to="/sign-up" className="mt-2 max-w-[300px]">
              Join Today for Free
            </ButtonLink>
            <div className="mt-4">
              <a
                href="#"
                onClick={handlePopupClick}
                className="italic text-snow-white underline"
              >
                Why is it Free?
              </a>
            </div>
            <div className="flex gap-4 justify-between lg:justify-normal">
              <AppLinks />
            </div>
          </div>
          <div className="lg:w-2/3 hidden lg:flex justify-end">
            <img className="self-start" src={DesktopMobileApp} loading="lazy" />
          </div>
        </div>
        <div className="flex flex-col items-center">
          <h3 className="text-lg lg:text-2xl text-snow-white mt-4 text-center uppercase max-w-6xl">
            Create Routes, Monitor Work Completed, Manage Customer Data, Develop
            Material and Service Reports, Track Employee Hours, and Much More
          </h3>
          <h3 className="text-xl text-snow-white mt-6 text-center font-bold">
            Created by Landscapers for Landscapers
          </h3>
        </div>
      </div>
      <div className="bg-landing-page-black relative">
        <div className="absolute right-0 bg-[url('./resources/landing-page/snow-icon-white.svg')] bg-cover h-[600px] w-[800px] z-0 opacity-10"></div>
        <div className="p-8 py-16 z-10 relative">
          <h3 className="text-3xl lg:text-4xl text-snow-white font-bold text-center">
            What Hurdles Is Your Business Facing?
          </h3>
          <ul className="mt-4 list-disc text-snow-white max-w-6xl mx-auto font-normal">
            <li>
              <h4 className="text-lg lg:text-2xl text-snow-white">
                Disorganized paperwork?
              </h4>
            </li>

            <li>
              <h4 className="text-lg lg:text-2xl text-snow-white">
                Trouble managing subcontractors?
              </h4>
            </li>
            <li>
              <h4 className="text-lg lg:text-2xl text-snow-white">
                Can't get yourself out of the field?
              </h4>
            </li>
            <li>
              <h4 className="text-lg lg:text-2xl text-snow-white">
                Need to track progress and profitability?
              </h4>
            </li>
            <li>
              <h4 className="text-lg lg:text-2xl text-snow-white">
                Can't accurately track employee hours?
              </h4>
            </li>
            <li>
              <h4 className="text-lg lg:text-2xl text-snow-white">
                Slip and fall lawsuits?
              </h4>
            </li>
            <li>
              <h4 className="text-lg lg:text-2xl text-snow-white">
                Clients disputing services with no proof to back up your work?
              </h4>
            </li>
          </ul>
          <h4 className="text-xl text-snow-white font-bold text-center mt-4">
            SnowScape Can Help!
          </h4>
        </div>
      </div>
      <div className="h-full bg-full-black/30 bg-[url('./resources/landing-page/grass.jpg')] bg-center bg-cover p-4 lg:p-8 relative bg-blend-darken">
        <MaxWidth>
          <h2 className="text-2xl lg:text-8xl text-snow-white font-extrabold mt-4 uppercase text-center lg:text-left ">
            The
            <br />
            Best App For
            <br />
            Lawn Care
            <br />
            Companies
          </h2>
          <h3 className="text-2xl italic text-snow-white mt-4 text-center lg:text-left font-bold max-w-[1300px]">
            "...I have found this program to be incredibly helpful. It is
            exactly what I needed to keep our lawn route organized so I do not
            have to be in the field. I think more people should know about this,
            it has really helped us..."
          </h3>
          <h3 className="text-2xl font-bold italic text-snow-white mt-1 text-center lg:text-left lg:ml-20">
            - Dan H
          </h3>
        </MaxWidth>
      </div>
      <div
        className="bg-landing-page-gray py-8 lg:py-20 bg-[url('./resources/landing-page/snow-icon-light-gray.svg')] bg-no-repeat font-bold"
        style={{ backgroundSize: "800px", backgroundPosition: "10%" }}
      >
        <h3 className="text-4xl text-landscape-primary font-bold text-center leading-none">
          How Does It Work?
        </h3>
        <h4 className="text-lg text-landing-page-black font-normal text-center">
          Simple, to the point, and easy to onboard
        </h4>
        <div className="flex flex-col md:flex-row lg:max-w-4xl md:mx-auto px-4 justify-between items-center text-xl">
          <div className=" mt-8 lg:mt-4">
            <h5 className="text-landing-page-black font-bold">
              Admin Portal - Desktop Version
            </h5>
            <ol className="list-disc text-landing-page-black list-inside mt-4 font-normal">
              <li className="mb-1">Build routes to track work</li>
              <li className="mb-1">Live job service data / reports</li>
              <li className="mb-1">Time clock management</li>
            </ol>
          </div>
          <div className=" mt-8 lg:mt-4">
            <h5 className="text-landing-page-black font-bold">
              In-Field Portal - Mobile App
            </h5>
            <ol className="list-disc text-landing-page-black list-inside mt-4 font-normal">
              {/* <li className="mb-1">Login quickly</li> */}
              <li className="mb-1">Select route and log job details</li>
              <li className="mb-1">Track materials used/time</li>
              <li className="mb-1">Track hours and clock in</li>
            </ol>
          </div>
        </div>
        {/* <div className="grid grid-cols-8 max-w-7xl mx-auto mt-4">
          <img src={Screen1} alt="Screen 1" />
          <img src={Screen2} alt="Screen 2" />
          <img src={Screen3} alt="Screen 3" />
          <img src={Screen4} alt="Screen 4" />
          <img src={Screen5} alt="Screen 5" />
          <img src={Screen6} alt="Screen 6" />
          <img src={Screen7} alt="Screen 7" />
          <img src={Screen8} alt="Screen 8" />
        </div> */}
        <img className="m-auto md:mt-8" src={DesktopMobileApp} />
      </div>
      <div className="bg-landing-page-black p-8 py-16 bg-repeat-x bg-[url('./resources/landing-page/snowflake-pattern.webp')]">
        <h2 className="text-4xl mb-4 text-snow-white font-bold mt-4 text-center">
          Learn More
        </h2>
        <h3 className="text-xl text-snow-white text-center">
          Tell us just a little bit about yourself. <br />
          Fill out this form below to learn more about the app, desktop version,
          or to talk to one of the founders!
        </h3>
        <h4 className="text-lg text-snow-white text-center max-w-6xl mx-auto mt-12 lg:mt-4"></h4>
        <h4 className="text-lg text-snow-white text-center max-w-6xl mx-auto mt-12 lg:mt-4">
          The best part about SnowScape is that it was developed and is run by
          two landscape company owners. We would be happy to talk shop, or help
          with your business operation. We created SnowScape to help us grow and
          scale the operations of our landscape business. When we first started
          to grow, we only wish we had a tool available to us like SnowScape. If
          you're just starting that journey and are a solo owner/operator, we
          want to make sure you have this tool to help get you over the hump and
          beyond so you can achieve your dreams of a business that runs like a
          well oiled machine.
        </h4>
        {/* FORM GOES HERE */}
        <div className="flex-1 h-[900px] lg:h-[700px] mt-10">
          <iframe
            src="https://api.leadconnectorhq.com/widget/form/44RozebCQVZCHS4I1zYJ"
            style={{
              width: "100%",
              height: "100%",
              border: "none",
              borderRadius: "3px",
            }}
            id="inline-44RozebCQVZCHS4I1zYJ"
            data-layout="{'id':'INLINE'}"
            data-trigger-type="alwaysShow"
            data-trigger-value=""
            data-activation-type="alwaysActivated"
            data-activation-value=""
            data-deactivation-type="neverDeactivate"
            data-deactivation-value=""
            data-form-name="CTA 1"
            data-height="664"
            data-layout-iframe-id="inline-44RozebCQVZCHS4I1zYJ"
            data-form-id="44RozebCQVZCHS4I1zYJ"
            title="CTA 1"
          ></iframe>
          <script src="https://link.msgsndr.com/js/form_embed.js"></script>
        </div>
        <h2 className="text-4xl text-snow-white font-bold mt-4 text-center lg:mt-20">
          Why Choose Us?
        </h2>
        <h4 className="text-lg text-snow-white text-center max-w-6xl mx-auto mt-4">
          As landscape business owners ourselves, we understand the hurdles and
          growing pains you face when trying to organize, grow, and scale your
          business. We've been through these growing pains and developed
          SnowScape to take the tracking, route planning, and organization
          hurdles out of your operations. Learn from us.{" "}
          <span className="text-landscape-secondary">
            With SnowScape you're getting the support of green industry business
            owners that have been in your shoes.
          </span>{" "}
          And have made it to the other side.
        </h4>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mt-8 lg:mt-20 max-w-[1818px] m-auto">
          <VideoCard
            url="https://www.youtube-nocookie.com/embed/hyiInDmCzHw?si=kVs8eZ9ev7LCguv-"
            title="Protect"
            icon={ShieldIcon}
          >
            With time stamped reports and pictures, there is never a guess as to
            what was done at the job site and when.
          </VideoCard>
          <VideoCard
            url="https://www.youtube.com/embed/dtHFxejiSv0?si=YekuA76oi4XC9dLG"
            title="Manage"
            icon={GearIcon}
            className="border-full-black border"
          >
            Create routes with all the details you need for each individual job.
            From site maps, to lock combinations, and everything in between!
          </VideoCard>
          <VideoCard
            url="https://www.youtube-nocookie.com/embed/LDLuEJPhqfk?si=v53nXtpSANwLm1ZT"
            title="Streamline"
            icon={MergeIcon}
          >
            Everything is in one place. With a super siple platform, little to
            no training is needed. Even the most technologically illiterate
            person can figure it out.
          </VideoCard>
        </div>
      </div>
      {/* <div className="h-full bg-full-black/30 bg-[url('./resources/landing-page/snow-removal.jpg')] bg-center bg-cover p-4 lg:p-8 relative bg-blend-darken"> */}
      <div className="h-full bg-full-black/30 bg-[url('./resources/landing-page/snow-to-grass.jpg')] bg-center bg-cover p-4 lg:p-8 relative bg-blend-darken">
        <MaxWidth>
          <h2 className="text-2xl lg:text-8xl text-snow-white font-extrabold mt-4 uppercase text-left ">
            Both
            <br />
            Seasons
            <br />
            Covered
          </h2>
          <h3 className="text-2xl text-snow-white mt-4 text-left font-bold max-w-[800px]">
            Do you work year-round? SnowScape has you covered! Easily switch
            between seasons.
          </h3>
        </MaxWidth>
      </div>

      <LandingPageModal
        open={isPopupOpen}
        closeModal={() => setIsPopupOpen(false)}
      />
    </div>
  );
};

export default HomePage;
