import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// Components
import RoundButton from "../../components/Buttons/RoundButton";
import LabeledTextboxInput from "../../components/Inputs/LabeledTextboxInput";

// Redux
import { updateUser } from "../../store/reducers/currentUserSlice";

// Services
import axiosCompanies from "../../services/axios/companies";
import axiosUsers from "../../services/axios/users";
import SplitForm from "../../components/SplitForm";
import SquareButton from "../../components/Buttons/SquareButton";
import AuthLabeledTextInput from "../../components/Inputs/AuthLabeledTextInput";
import flash from "../../services/flash";

const AuthCreateFirstCompany = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [companyName, setCompanyName] = useState("");
  const [createLoading, setCreateLoading] = useState(false);

  const handleCreateCompany = () => {
    setCreateLoading(true);

    // Submit updated job to server
    axiosCompanies.addCompany(
      { name: companyName, uid: props.currentUser.uid },
      (companyData) => {
        setCreateLoading(false);
        axiosUsers.getUserById({ uid: props.currentUser.uid }, (user) => {
          // Update user, use first company as new companyId
          dispatch(
            updateUser({
              ...user,
              currentCompanyId: companyData.company._id,
            }),
          );
          navigate("/admin");
          flash.success("Company created!");
        });
      },
    );
  };
  const leftContent = (
    <div className="">
      Thanks for joining our family! Now that you're ready to go, let's create
      your first company.
    </div>
  );
  return (
    <SplitForm leftContent={leftContent}>
      <div className="grid h-full">
        <div className="text-full-white place-self-center">
          <h1 className="mb-3 text-center font-bold text-3xl text-full-white">
            Name Your Company
          </h1>
          <AuthLabeledTextInput
            label="Company Name"
            placeholder="Green Landscaping LLC..."
            setValue={(val) => {
              setCompanyName(val);
            }}
            value={companyName}
          />
          <SquareButton
            label={"Create"}
            color="blue"
            disabled={createLoading}
            onClick={handleCreateCompany}
          />
        </div>
      </div>
    </SplitForm>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(AuthCreateFirstCompany);
