import React, { useCallback, useEffect, useRef } from "react";
import AuthLabeledTextInput from "../../components/Inputs/AuthLabeledTextInput";
import { useState } from "react";
import SquareButton from "../../components/Buttons/SquareButton";
import { current } from "@reduxjs/toolkit";

const ValidateCodeForm = ({ phone, handleSubmit, resendCode }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState("");
  const [secondsTillResend, setSecondsTillResend] = useState(30);
  const countdownInterval = useRef(null);

  const onFormSubmit = async (e) => {
    setIsLoading(true);
    try {
      const form = e.nativeEvent.target;
      if (typeof handleSubmit === "function") {
        await handleSubmit(form.code.value);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const startCountdown = useCallback(() => {
    setSecondsTillResend(30);
    countdownInterval.current = setInterval(() => {
      setSecondsTillResend((currentValue) => {
        if (currentValue < 1) {
          clearInterval(countdownInterval.current);
        } else {
          return currentValue - 1;
        }
      });
    }, 1000);
  }, []);

  useEffect(() => {
    startCountdown();
    return () => {
      if (countdownInterval.current) {
        clearInterval(countdownInterval.current);
      }
    };
  }, [startCountdown]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onFormSubmit(e);
      }}
      className="validate-code-form p-2"
      data-testid="validate-code-form"
    >
      <h1 className="text-2xl lg:text-3xl font-bold">Confirm Your Phone</h1>
      <p>
        We just sent a code to {formatPhone(phone)}. Please enter it and press
        verify.
      </p>
      <div className="text-xs text-[#808080]">
        {secondsTillResend > 0 ? (
          <>Resend code in {secondsTillResend} seconds</>
        ) : (
          <button
            type="button"
            onClick={async () => {
              await resendCode();
              startCountdown();
            }}
            className="underline"
          >
            Resend Code
          </button>
        )}
      </div>
      <AuthLabeledTextInput
        disabled={false}
        placeholder="Code"
        name="code"
        label="SMS Code"
        required
        minLength={6}
        maxLength={6}
        value={value}
        setValue={setValue}
        showValidation={false}
        autoFocus
      />

      <SquareButton
        label="Verify"
        color="blue"
        loading={isLoading}
        disabled={isLoading || value?.length !== 6}
        className="max-w-none"
        testId="validate-code-submit"
        type="submit"
      />
    </form>
  );
};

function formatPhone(number) {
  let matches = number?.match(/(\d{3})(\d{3})(\d{4})/);
  if (matches) {
    return `+1 ${matches[1]} ${matches[2]} ${matches[3]}`;
  }
  return number;
}

export default ValidateCodeForm;
