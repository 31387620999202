import React from "react";
import LogoVertical from "../resources/logo/logo-vertical.svg";

const CenteredLogo = () => (
  <div className="grid h-full">
    <div className="place-self-center">
      <img className="w-[350px]" src={LogoVertical} loading="lazy" />
    </div>
  </div>
);
const defaultLeftContent = <CenteredLogo />;
const SplitFormSmall = ({ children, leftContent, className }) => {
  return (
    <div
      className={`mt-20 border border-[#50565F] rounded-xl px-12 py-10 bg-gradient-to-r from-landing-page-black to-landing-page-gradient-start max-w-[1200px] ${className}`}
    >
      <div className="grid grid-cols-1 lg:grid-cols-2">
        <div className="">{leftContent ?? defaultLeftContent}</div>
        <div className="mt-4 lg:mt-0">{children}</div>
      </div>
    </div>
  );
};

export default SplitFormSmall;
export { CenteredLogo };
