import { CardElement } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import CardForm from "./CardForm";
import PulseLoader from "react-spinners/PulseLoader";

/**
 * @typedef BillingInformation
 * @property {string} cardExpirationDate
 * @property {string} cardLastFour
 * @property {string} customerId
 * @property {string} nameOnCard
 * @property {string} nextChargeAmount
 * @property {string} type
 */

/**
 *
 * @param {{billingInfo: BillingInformation|undefined}} props
 */
export default function PaymentMethod({
  billingInfo,
  currentUser,
  onChange,
  onRemoveClick,
}) {
  const [showAddForm, setShowAddForm] = useState(false);
  return (
    <div
      className={`mb-2 shadow-lg w-[337px] h-[212px] from-[#3274f0] to-[#9ec0f7] bg-gradient-to-br text-[#bac3c8] rounded-xl p-5 `}
    >
      {showAddForm ? (
        <CardForm
          currentUser={currentUser}
          onSuccess={() => {
            setShowAddForm(false);
            onChange();
          }}
        />
      ) : (
        <>
          {billingInfo ? (
            <div className="grid grid-cols-2 grid-rows-3 h-full">
              <h2 className="text-2xl text-full-white col-span-2">
                Your Payment Method
              </h2>
              <div
                className="flex gap-4 col-span-2"
                style={{ textShadow: "#555555 1px 1px 2px" }}
              >
                <span>XXXX</span>
                <span>XXXX</span>
                <span>XXXX</span>
                <span>{billingInfo ? billingInfo.cardLastFour : "XXXX"}</span>
              </div>
              <div
                className="self-center justify-self-start"
                style={{ textShadow: "#555555 1px 1px 2px" }}
              >
                {billingInfo ? billingInfo.nameOnCard : ""}
              </div>
              <CardButton
                onClick={onRemoveClick}
                className="self-center justify-self-end hover:border-snow-red hover:text-snow-red"
              >
                Remove
              </CardButton>
            </div>
          ) : (
            <div className="grid grid-cols-1 grid-rows-4 h-full">
              <h2 className="text-2xl text-full-white ">
                No Payment Method on File
              </h2>
              <CardButton
                onClick={() => setShowAddForm(true)}
                className="row-span-3 justify-self-center self-center "
              >
                + Add Payment Method
              </CardButton>
            </div>
          )}
        </>
      )}
    </div>
  );
}
function CardButton({ children, onClick, className, disabled, loading }) {
  return (
    <button
      disabled={disabled || loading}
      onClick={onClick}
      className={`p-2 rounded-md border border-full-white text-full-white disabled:text-landing-page-gray disabled:border-landing-page-gray font-bold ${className}`}
    >
      {loading ? (
        <PulseLoader
          color={"#fffafa"}
          size={5}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        children
      )}
    </button>
  );
}
export { CardButton };
