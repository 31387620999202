import React from "react";
import BasicInput from "../Inputs/BasicInput";
const InputWithIcon = ({
  icon,
  disabled,
  inputMode: type,
  maxLength,
  onChange,
  placeholder,
  value,
  className,
}) => {
  return (
    <BasicInput
      className={`bg-[url('${icon ?? "./resources/search-gray.svg"}')] pl-12 ${className ?? ""}`}
      disabled={disabled}
      type={type}
      maxLength={maxLength}
      onChange={onChange}
      placeholder={placeholder ?? "Search..."}
      value={value}
    />
  );
};

export default InputWithIcon;
