import React from "react";
import LoadingIcon from "./Loaders/LoadingIcon";
export default function ContentWrapper({ children, loading = false }) {
  return (
    <div className="p-4 md:p-10 w-full">
      {loading ? (
        <div className=" flex justify-center">
          <LoadingIcon />
        </div>
      ) : (
        children
      )}
    </div>
  );
}
