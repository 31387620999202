import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { connect, useDispatch } from "react-redux";
import { Outlet, ScrollRestoration } from "react-router-dom";

// Components
import LoadingIcon from "../../components/Loaders/LoadingIcon";
import ManageSubscriptionModal from "../../components/Modals/ManageSubscriptionModal";
import AdminSideNavBar from "../../components/Navigation/AdminSideNavBar";
import AdminTopNavBar from "../../components/Navigation/AdminTopNavBar";
import SubscriptionPaywall from "../../components/Stripe/SubscriptionPaywall";

// Redux
import { closeManageSubscriptionModal } from "../../store/reducers/metadataSlice";

// Services
import FlashMessage from "../../components/FlashMessage";
import firebaseAuth from "../../services/firebase/firebaseAuth";

const AdminWrapper = (props) => {
  const dispatch = useDispatch();

  const [showLoading, setShowLoading] = useState(true);
  const [user] = useAuthState(firebaseAuth.auth);

  useEffect(() => {
    // When user object has loaded from firebase, display.
    // Required for authorization - not requesting data before fb user.idToken is loaded
    if (user) {
      setShowLoading(false);
    }
  }, [user]);

  return (
    <div className="">
      <AdminTopNavBar />
      <AdminSideNavBar />
      <FlashMessage />

      {showLoading ? (
        <div className="flex justify-center">
          <LoadingIcon />
        </div>
      ) : (
        <>
          <SubscriptionPaywall />
          <div className="pt-20 print:pt-0 sm:pl-56 print:pl-0 absolute top-0 bottom-0 left-0 right-0">
            <Outlet />
          </div>

          <ManageSubscriptionModal
            adjustForSidebar
            onClose={() => dispatch(closeManageSubscriptionModal())}
            open={props.metadata.displayManageSubscriptionModal}
          />
        </>
      )}
      <ScrollRestoration />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { metadata } = state;
  return { metadata };
};

export default connect(mapStateToProps)(AdminWrapper);
