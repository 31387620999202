import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

// Components
import SignOutModal from "../Modals/SignOutModal";

// Resources
import HamburgerIcon from "../../resources/hamburger-white.svg";
import SnowScapeLogoWithWhiteText from "../../resources/logo/snowscape-logo-horizontal-with-white-text.svg";
import UnlockBlueIcon from "../../resources/unlock-blue.svg";

// Services
import firebaseAuth from "../../services/firebase/firebaseAuth";

const AdminTopNavBar = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [displayMobileMenu, setDisplayMobileMenu] = useState(false);

  const [showSignoutModal, setShowSignoutModal] = useState(false);

  const navItems = [
    {
      label: "Routes",
      url: "/admin/route",
      mobileOnly: true,
      spaceBefore: true,
    },
    { label: "Properties", url: "/admin/property", mobileOnly: true },
    {
      label: "Property History",
      url: "/admin/property/history",
      mobileOnly: true,
    },
    { label: "Employees", url: "/admin/employee", mobileOnly: true },
    {
      label: "Services",
      url: "/admin/company/services",
      mobileOnly: true,
    },
    { label: "Time Clock", url: "/admin/timeclock", mobileOnly: true },
    { label: "Materials", url: "/admin/material", mobileOnly: true },
    { label: "Reports", url: "/admin/report", mobileOnly: true },
    {
      label: "Companies",
      url: "/admin/settings/company",
      mobileOnly: true,
      spaceBefore: true,
    },
    {
      label: "Billing",
      url: "/admin/settings/billing",
      mobileOnly: true,
    },
    {
      label: "Profile",
      url: "/admin/settings/profile",
      mobileOnly: true,
    },
  ];

  const handleHamburgerClicked = () => {
    setDisplayMobileMenu(!displayMobileMenu);
  };

  const handleSignout = () => {
    firebaseAuth.signOut(() => {
      dispatch({ type: "SIGNOUT_CLEAR_STORE" });
      navigate("/");
    });
  };

  return (
    <>
      <nav className="w-full fixed h-20 bg-snow-blue-3 md:px-10 px-4 flex z-40 drop-shadow-2xl print:hidden">
        <div className="flex justify-between w-full">
          <Link to="/admin" className="flex">
            <img
              alt="snowscape-logo"
              className="my-auto"
              src={SnowScapeLogoWithWhiteText}
            />
            {/* <img
                        className='w-12 my-auto'
                        src={SnowScapeLogo}
                        alt='snow-logo'
                    />
                    <p className='ml-3 my-auto text-3xl font-light text-snow-white'>
                        SnowScape
                    </p> */}
          </Link>
          {/* Mobile Hamburger Menu */}
          <div
            className="self-center cursor-pointer sm:hidden"
            onClick={handleHamburgerClicked}
          >
            <img alt="hamburger-menu" className="" src={HamburgerIcon} />
          </div>

          {/* Mobile Dropdown Menu */}
          <ul
            className={`fixed sm:hidden w-full left-0 top-20 bg-snow-white float-right text-center transition-all duration-700 ${
              displayMobileMenu
                ? "h-screen overflow-y-scroll pb-40"
                : "h-0 overflow-hidden"
            }`}
          >
            <li
              className={`flex flex-row w-full justify-center leading-8 mt-10 transition-all text-xl uppercase select-none font-bold hover:opacity-60 active:opacity-40`}
            >
              <Link
                to="/admin/settings/billing"
                className="flex flex-row w-full justify-center"
              >
                <img alt="upgrade" className="w-7 h-7" src={UnlockBlueIcon} />
                <div className="font-bold ml-2">Upgrade</div>
              </Link>
            </li>
            {navItems.map((item, key) => (
              <li
                className={`leading-8 m-10 transition-all text-xl uppercase select-none font-bold ${
                  item.spaceBefore ? "pt-10 border-t-[1px]" : ""
                }`}
                key={key}
              >
                <Link
                  className={`mx-4 hover:opacity-50 ${
                    location.pathname === item.url
                      ? "text-snow-green"
                      : "text-snow-primary"
                  }`}
                  onClick={handleHamburgerClicked}
                  to={item.url}
                >
                  {item.label}
                </Link>
              </li>
            ))}
            <li
              className={`leading-8 m-10 transition-all text-xl uppercase select-none font-bold`}
            >
              <div
                className="mx-4 text-snow-red cursor-pointer"
                onClick={() => setShowSignoutModal(true)}
              >
                Sign Out
              </div>
            </li>
          </ul>

          {/* Desktop Menu */}
          <div className="hidden sm:flex flex-row justify-between my-auto font-bold text-snow-white">
            {navItems.map((item, key) => (
              <div key={key}>
                {!item.mobileOnly ? (
                  <Link className={`mx-4 hover:underline`} to={item.url}>
                    {item.label}
                  </Link>
                ) : null}
              </div>
            ))}

            <div
              className="mx-4 cursor-pointer"
              onClick={() => setShowSignoutModal(true)}
            >
              Sign Out
            </div>
          </div>
        </div>
      </nav>
      <SignOutModal
        closeModal={() => setShowSignoutModal(false)}
        handleSignout={handleSignout}
        open={showSignoutModal}
      />
    </>
  );
};

// export default AdminTopNavBar;
const mapStateToProps = (state) => {
  const { currentUser, metadata } = state;
  return { currentUser, metadata };
};

export default connect(mapStateToProps)(AdminTopNavBar);
