import React from "react";
import { Outlet } from "react-router-dom";

function SignUpLayout() {
  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateRows: "1fr",
          minHeight: "100vh",
        }}
      >
        <div className="text-snow-white content-wrapper bg-gradient-to-br from-landing-page-gradient-start to-landing-page-black content">
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default SignUpLayout;
