import React from "react";
import AppleAppStoreLogo from "../../../resources/app-store/apple-app-store-logo.svg";
import GooglePlayStoreLogo from "../../../resources/app-store/google-play-store-logo.svg";
export default function AppLinks() {
  return (
    <>
      <a
        href="https://play.google.com/store/apps/details?id=com.trayl.dev.snow"
        data-testid="google-play-link"
      >
        <img
          src={GooglePlayStoreLogo}
          alt="google-play-store-logo"
          className="w-44 my-6 md:w-40 hover:opacity-70"
        />
      </a>
      <a
        href="https://apps.apple.com/us/app/snowscape-management/id6443944347"
        data-testid="apple-store-link"
      >
        <img
          src={AppleAppStoreLogo}
          alt="apple-app-store-logo"
          className="w-44 my-6 md:w-40 hover:opacity-70"
        />
      </a>
    </>
  );
}
