// eslint-disable-next-line no-unused-vars
import React from "react";
import { useNavigate } from "react-router-dom";

// Kept as a redirect for now
const GetStartedPage = () => {
  const navigate = useNavigate();
  navigate("/sign-up");
};

export default GetStartedPage;
