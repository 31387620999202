import React from "react";
import PulseLoader from "react-spinners/PulseLoader";

function SubtleButton({
  onClick,
  disabled,
  loading,
  loaderColor,
  loaderSize,
  label,
  children,
  className,
  testId,
}) {
  return (
    <button
      onClick={(e) => {
        if (!loading) onClick(e);
      }}
      disabled={disabled}
      className={`border border-[#cccccc] rounded-md p-2 text-sm shadow-md font-semibold bg-full-white text-full-black ${className ?? ""}`}
      data-testid={testId ?? null}
    >
      {loading ? (
        <PulseLoader
          color={loaderColor || "#fffafa"}
          size={loaderSize || 5}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        label ?? children
      )}
    </button>
  );
}

export default SubtleButton;
