import React, { useEffect, useState } from "react";

// Components
import StandardButton from "../../components/Buttons/StandardButton";
import LabeledTextboxInput from "../../components/Inputs/LabeledTextboxInput";

// Resources
import MailIcon from "../../resources/mail.svg";

// Services
import axiosContact from "../../services/axios/contact";
import { SuccessMessage } from "./ContactUsPage";
import SplitFormSmall from "../../components/SplitFormSmall";
import BasicInput from "../../components/Inputs/BasicInput";
import SquareButton from "../../components/Buttons/SquareButton";

const QuotePage = () => {
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [successfullySent, setSuccessfullySent] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Verify Email
    // Regular expression for basic email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailValid(emailPattern.test(email));
  }, [email]);

  const handleSendMessage = async () => {
    setLoading(true);
    try {
      const data = await axiosContact.sendContactUsEmail({
        type: "get-quote",
        email,
        name,
        message,
      });

      if (data.success) {
        setSuccessfullySent(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const form = !successfullySent ? (
    <div className="">
      <h1 className="text-3xl font-bold mb-2">Get a Quote</h1>
      <p className="text-sm opacity-80">
        You can also email our support team at{" "}
        <a className="underline" href="mailto:info@mysnowscape.com">
          info@mysnowscape.com
        </a>
        .
      </p>
      {/* Name */}
      <BasicInput
        disabled={false}
        // label="Name"
        placeholder="John Doe"
        onChange={setName}
        value={name}
      />
      {/* Email */}
      <BasicInput
        disabled={false}
        // label="Email"
        placeholder="your-email@gmail.com"
        onChange={setEmail}
        value={email}
      />
      {/* Message */}
      <BasicInput
        disabled={false}
        multiline
        // label="Message"
        placeholder="Type your message here..."
        onChange={setMessage}
        value={message}
      />

      <div className="w-full mb-12">
        <SquareButton
          disabled={!name || !email || !message || !emailValid}
          label="Get Quote"
          onClick={handleSendMessage}
          className="max-w-none"
          loading={loading}
        />
      </div>
    </div>
  ) : (
    SuccessMessage
  );

  return (
    <>
      <SplitFormSmall className="mx-auto">{form}</SplitFormSmall>
    </>
  );
};

export default QuotePage;
