import React, { useState } from "react";

// Resources
import ExpandArrow from "../../resources/expand-arrow.svg";
import WithSnowFlakes from "../../components/LandingPage/WithSnowFlakes";
import InputWithIcon from "../../components/Filters/InputWithIcon";

const AboutPage = () => {
  // Questions and their answers
  const questionAnswerList = [
    {
      question: "Do I have to download the app?",
      answer:
        "Yes, the SnowScape app is the only way to log services in the field.  This can be done on any device that the app is downloaded on (phone, tablet etc)",
    },
    {
      question: "What is SnowScape?",
      answer:
        "In short, SnowScape is an app that helps service based businesses manage tasks/routes/job orders for their crews and operators and track that information in real time.  But it is much more than that, with SnowScape you can track materials used, man hours spent on a job or property, track hours for payroll and much more.  Visit our “Features” page and “About Us” page to find out more.",
    },
    {
      question: "What is the SnowScape app?",
      answer:
        "The Snowscape App is the in field downloadable app that employees and operators use to log services, track and manage materials used, and manage their own routes. The app is downloadable on any iphone/tablet on the Apple or Google Play store.",
    },
    {
      question:
        "Am I limited to a certain number of properties, pictures or service reports every month?",
      answer:
        "No, there is no limit of properties, pictures or service reports. All the information will be there for you to access.",
    },
    {
      question: "What package is best for me?",
      answer:
        "Depending on the size of your company, and how many employees you have will depend on what package will be right for you. If you are just a solo operator with one truck and yourself with maybe a few other laborers, Hans Solo will be your ticket. If you have one company, and do not sub any work out that you would like the app to track, with under 20 trucks in the field/employees you would like to use the app, “Team” Is the right pick for you. If you are a multiple facet company with under 100 employees and sometimes need to add a sub contractor onto your app, or have multiple companies. Squad, would be there right choice for you. If you have a larger scale operation with 100+ users, and you manage multiple subcontractors, “Fleet” would be a great option for you. This also would be a great option if you are looking to expand your company or have multiple companies under one roof. All easily switchable to have all of your companies in one place. You could also use “Fleet” for a property management company with multiple vendors. The app allows you to monitor each vendor separately.",
    },
    {
      question: "What is the purpose of having another company in my package?",
      answer:
        "The main purpose of having another company in your package is if you subcontract work out, and want the company to use your app to be able to track the work done.",
    },
    {
      question: "What happens to my data if I cancel my subscription?",
      answer:
        "If you cancel your subscription, the data will always be there to access. If you want to re-subscribe to change or update data, you can. You wont be able to use all the functions of the app if you cancel, but you can always still see your reports.",
    },
    {
      question: "Who has access to the information I put into SnowScape?",
      answer:
        "The Admin, is the only person who can change information or see pertinent company information on Snowscape. The app developer can help with questions you may have but does not have access to information within the app. Your information is private and safe. When set up, employees don’t have access to anything but their routes and time clock management. They cannot change anything in the app as well, unless they are an Admin.",
    },
    {
      question: "Do I have to sign up for auto billing?",
      answer:
        "When subscribing to the app, auto billing is necessary to be on, to ensure the app works and functions month to month. You can always cancel at any time, or change your payment method through our billing portal. But if you cancel, some of the functions of the app no longer will work for you. You will only be able to generate reports and see your company information.",
    },
    {
      question:
        "What happens if I sign up for service in the middle of the month, do I pay for a full month?",
      answer:
        "Your billing cycle starts the day you subscribe, and continues for one month. If you sgn up on the 5th of January, one month is considered until the 5th of February. Your subscription payment will be autobilled on the 5th of every month. This is also changeable on the billing portal if you prefer to have your payment deducted on a certain day of the month.",
    },
    {
      question:
        "What happens if I cancel my subscription in the middle of a billing cycle?",
      answer:
        "We don't offer prorated packages, once you subscribe, you are locked in for a month. If you cancel with days remaining on your month subscription, you will have access to use the app to its full capabilities until the month runs out. After that you wouldn't be billed again, but would still have access to the reports and admin portals, but would not have full use of all of the features of the App.",
    },
    {
      question:
        "Will I be able to record my services if the jobsite is in a location with limited or no cell reception?",
      answer:
        "Yes, one of the most important features of SnowScape  when developing it, was to backlog information when a phone doesn't have service. A big issue with low service areas is, apps stop working when there is no service. Snowscape continues to work no matter what. The information logged with limited service will upload to the app’s servers when good connection of cellular services or wifi is reconnected.",
    },
    {
      question:
        "Can SnowScape track my employees hours worked to use for payroll?",
      answer:
        "Yes, there is a timeclock management feature to Snowscape. Employees can clock in, with a GPS timestamp of where they are when they clock in. This feature can be exported in reports to give hours worked weekly for Payroll.",
    },
    {
      question:
        "Do I have to enable my location services on my mobile device to use the app?",
      answer:
        "In order for the app to work to its full potential, and not have errors in service. Location permissions should always be set to “Allow when using” or “Always”. The point of this, is to make sure if there is any discrepancies in timing of service with a client, the app is geotagged and time stamped showing crews and employees where on site, and when they were onsite.",
    },
    {
      question: "What reports can I generate as an Admin?",
      answer:
        "Reports are the bread and butter of why we developed Snowscape. Anything that you put in SnowScape, can be tracked and a report can be generated for. The main focus is managing data;; By Property (managing what materials and how much time has been used at each property) By Employee (Managing and tracking how long an employee is taking at each property, are they applying or using too much materials, is the drive time between properties extensive and inefficient) By Material (Tracking all materials used for reports)",
    },
    {
      question:
        "My company performs services that are unique, how can I track that within the app?",
      answer:
        "When setting up a specific property, you can add materials/services to that specific property to track. This allows each property to have a unique set of services. All customizable and trackable.",
    },
    {
      question:
        "I want to upgrade or downgrade my subscription, how do I do that?",
      answer:
        "On the website side of the software, you can manage your subscription on your Admin portal. You can either upgrade or downgrade. Upgrading will supersede your current subscription,  and make the features available to you right away. Downgrading will happen on the last day  of your billing cycle. If you are downgrading from multiple companies down to one, you will need to select which company you are continuing to use. You can still access a second companies information, but will not be able to log or use the app for that, said company.",
    },
    {
      question: "Can SnowScape put my properties in an optimized route?",
      answer:
        "Snowscape, as of now, is not a routing software. There is many other websites that can do this, but Snowscape cannot.",
    },
    {
      question:
        "I have subcontractors that work for me, how do I set them up within SnowScape?",
      answer:
        "Another great feature of the app, is the use of it for tracking and monitoring subcontractors. There is two ways to do this, you can either upgrade subscriptions to enable more companies which is recommended. You would use that additional company on your subscription  to make the  subcontract its own separate company. This way they can set up their own routes within that company as well as the properties you are subcontracting to them, and you can make them an admin of that company so they can change information within the app but cannot access your other companies data. This, all while still visible to you as the admin of that company as well. Or, the second option,  you can add the subcontractor as an employee and create a specific route for them. The downfall of that second option would be that when logging into your company, they can see all the routes in your companies list of routes.",
    },
    {
      question: "Can I use SnowScape year round?",
      answer:
        "Yes, Snowscape can be used year round, and can be used for any service based business or property management business to track everything your company needs.",
    },
    {
      question:
        "Can I use SnowScape for non-snow or landscape related services?",
      answer:
        "Yes, Snowscape can be set up uniquely where you are able to input data you want to track. Specific materials you want to track, and can be customized to any service based or property management business.",
    },
    {
      question: "Can I use SnowScape on a computer or tablet?",
      answer:
        "Yes, SnowScape can be used on any Apple or Android phone or Tablet, as well as any PC or computer with our easy to use website.",
    },
    {
      question: "How can SnowScape help me with my bidding in the future?",
      answer:
        "A successful bid, is made by tracking numbers and data. Knowing your costs, knowing your labor hours and coming up with an accurate bid for each job you do. Our app doesnt create the bids for you, but it puts your hands on the data you need to make your business profitable. One year of using Snowscape and you can generate reports for each property, to show what materials were used, and how much time was spent on each property. Abling you to customize and generate profitable pricing, and also knowing when you should charge more or less based on profitability of the contract.",
    },
    {
      question: "Can I set up SnowScape to bill my clients?",
      answer:
        "One thing Snowscape is not, is a billing software. There are plenty of those out there that you can take information from Snowscape, and easily find information and dates of service that you need to create an accurate bill for your clients. If there is any questions on that bill, Snowscape has all of the information to prove your bill is accurate.",
    },
    {
      question:
        "If I only use SnowScape for winter, do I have to pay all year round?",
      answer:
        "While we always want year round users, the short answer is no. You can cancel at any time. Your account will stay active with information stored on our servers from data you logged previously and will be ready for you at any time. But if canceled you will not be able to use all the features of the app.",
    },
    {
      question:
        "The SnowScape system is intriguing, but there is one specific feature keeping me from using it.",
      answer:
        "We are always trying to grow and update SnowScape to be an amazing Management System that all users log. If the app is intriguing but there is something you want to add, and cant live without, reach out to us, and maybe its something we can add. We know all businesses have unique operations.",
    },
    {
      question:
        "If I terminate an employee will they still have access to our account?",
      answer:
        "If an employee is terminated, you can delete their employee user off of your account so they will not have access anymore.",
    },
    {
      question: "How do I change the date I pay my subscription each month?",
      answer:
        "On the admin portal, of the website, go the billing portal and edit the date your payment is auto billed each month.",
    },
    {
      question: "What happens if I change my phone number?",
      answer:
        "Logging into the app, uses a specific phone number for each employee. Which sends a code  to login right to your phone. If your phone number changes, or an employees phone number changes you will need to create a new employee for them with the company code. And give them access with their new phone number. To avoid using an additional employee for an inactive/old number, on the admin page, under manage employees, delete the old employee phone number off of the company access page.",
    },
    {
      question:
        "What is the difference between an admin, operator and employee?",
      answer:
        "The main difference is access. Admin’s can access anything and change any information. Employees can only see produced routes and log routes. They cannot change the order of routes or anything within the saved data of the route or property itself.",
    },
  ];

  const [displayedQA, setdisplayedQA] = useState(questionAnswerList);
  const [searchString, setSearchString] = useState("");

  const handleSearching = (searchString) => {
    setSearchString(searchString);

    const updatedDisplayedQA = questionAnswerList.filter(
      (item) =>
        item.question
          ?.toLocaleLowerCase()
          .includes(searchString.toLocaleLowerCase()) ||
        item.answer
          ?.toLocaleLowerCase()
          .includes(searchString.toLocaleLowerCase()),
    );
    setdisplayedQA(updatedDisplayedQA);
  };

  return (
    <WithSnowFlakes>
      <div className="flex flex-col w-full items-center px-4 md:py-10 py-4 overflow-scroll">
        <div className="w-full max-w-[1200px]">
          {/* Search Bar */}
          <div className="grid grid-cols-1 lg:grid-cols-[1fr_auto]">
            <h1 className="text-snow-white font-medium text-5xl text-center md:text-left">
              Frequently Asked Questions
            </h1>
            {/* <div className="flex flex-row w-full justify-end"> */}
            <div className="">
              <InputWithIcon
                onChange={handleSearching}
                value={searchString}
                className="w-full"
              />
            </div>
          </div>
          {displayedQA.length === 0 ? (
            <div className="mt-7 text-snow-white text-left font-light text-2xl">
              None found...
            </div>
          ) : null}
          <ol className="mt-7 text-snow-white text-left list-decimal ml-4">
            {displayedQA.map((item, key) => (
              <li key={key} className="mb-2">
                <a href={"#" + encodeURIComponent(item.question)}>
                  {item.question}
                </a>
              </li>
              // <QuestionAnswerWidget
              //   answer={item.answer}
              //   key={key}
              //   question={item.question}
              // />
            ))}
          </ol>
          <ol className="mt-7 text-snow-white text-left list-decimal ml-4">
            {displayedQA.map((item, key) => (
              <li key={key} className="mt-10">
                <a id={item.question} className="pt-20 target:font-bold">
                  <span className="text-xl mb-2">{item.question}</span>
                  <p>{item.answer}</p>
                </a>
              </li>
            ))}
          </ol>
        </div>
      </div>
    </WithSnowFlakes>
  );
};

const QuestionAnswerWidget = (props) => {
  const [displayAnswer, setDisplayAnswer] = useState(false);

  const handleClicked = () => {
    setDisplayAnswer(!displayAnswer);
  };

  return (
    <div className="flex flex-col w-full mb-8">
      <div className="flex flex-row" onClick={handleClicked}>
        <div className="font-bold text-2xl">{props.question}</div>
        <div
          className={`ml-4 transition-transform duration-500 transform ${
            displayAnswer ? "rotate-0" : "-rotate-90"
          }`}
        >
          <img alt="expand" className={``} src={ExpandArrow} />
        </div>
      </div>
      <div
        className={`font-light text-lg transition-max-h duration-700 ${
          displayAnswer ? "max-h-screen" : "max-h-0"
        } overflow-hidden`}
      >
        {props.answer}
      </div>
    </div>
  );
};

// const SearchBar = (props) => {
//   const onChange = (e) => {
//     props.handleSearching(e.target.value);
//   };

//   return (
//     <>
//       <div
//         className={`flex flex-row w-80 h-10 rounded-xl my-3 py-1.5 pr-5 text-snow-blue-3 bg-snow-extra-light-grey ${
//           props.disabled ? "opacity-40" : null
//         }`}
//       >
//         <img
//           alt="filter"
//           className="w-5 h-5 justify-center self-center mx-2"
//           src={SearchIconBlue}
//         />
//         <input
//           className="w-full text-lg font-light bg-snow-extra-light-grey border-none"
//           disabled={props.disabled}
//           inputMode={props.inputMode || "text"}
//           maxLength={props.maxLength}
//           onChange={onChange}
//           placeholder={props.placeholder || "Search..."}
//           value={props.value}
//         />
//       </div>
//     </>
//   );
// };

export default AboutPage;
