import React from "react";
import ModalWrapper from "../../../components/Modals/ModalWrapper";

const LandingPageModal = (props) => {
  return (
    <>
      {props.open ? (
        <ModalWrapper clickOff={props.closeModal}>
          <div
            className={`flex flex-col w-96 rounded-xl bg-snow-white text-full-black drop-shadow-2xl px-12 pt-12 pb-6 overflow-x-scroll ${
              props.adjustForSidebar ? "ml-60" : null
            }`}
          >
            <div className="flex flex-row justify-between w-full">
              <div className="text-xl font-bold font-snow-primary">
                Why is it free?
              </div>
            </div>
            <div className="text-base font-light mb-4">
              We created SnowScape to help us grow and scale the operations of
              our landscape business. When we first started to grow, we only
              wish we had a tool available to us like SnowScape. If you’re just
              starting that journey and are a solo owner/operator, we want to
              make sure you have this tool to help get you over the hump and
              beyond so you can achieve your dreams of a business that runs like
              a well oiled machine.
            </div>
          </div>
        </ModalWrapper>
      ) : null}
    </>
  );
};

export default LandingPageModal;
