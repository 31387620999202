import React from "react";
import LogoVertical from "../resources/logo/logo-vertical.svg";

const SplitForm = ({ children, leftContent }) => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 h-full">
      <div className="bg-[#191b1c] hidden lg:block">
        <div className="h-full hidden lg:grid">
          <div className="text-full-white place-self-center max-w-[500px]">
            <LogoAd />
            <div className="">{leftContent}</div>
          </div>
        </div>
      </div>
      <div className=" bg-[#202326] text-full-white">{children}</div>
    </div>
  );
};

const LogoAd = () => {
  return (
    <div className="mb-40">
      <img className="" src={LogoVertical} loading="lazy" />
    </div>
  );
};

export default SplitForm;
