import React, { useEffect } from "react";
import AuthLabeledTextInput from "../../components/Inputs/AuthLabeledTextInput";
import { useState } from "react";
import SquareButton from "../../components/Buttons/SquareButton";
import { Link, useSearchParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import firebaseAuth from "../../services/firebase/firebaseAuth";
import PersonalInfoFields from "./PersonalInfoFields";

const JoinForm = ({ phone, handleSubmit }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [firebaseUser, authLoading] = useAuthState(firebaseAuth.auth);
  const [searchParams] = useSearchParams();
  let urlJoinCode = searchParams.get("code");

  useEffect(() => {
    firebaseAuth.getRecaptchaVerify();
  }, []);

  const onFormSubmit = async (e) => {
    setIsLoading(true);
    try {
      const form = e.nativeEvent.target;
      const data = {
        phone: firebaseUser?.phoneNumber ?? form.phone.value,
        firstName: form.first_name?.value,
        lastName: form.last_name?.value,
        email: form.email?.value,
        joinCode: form.join_code.value,
        fbUser: firebaseUser,
      };
      if (typeof handleSubmit === "function") {
        await handleSubmit(data);
      }
    } finally {
      setIsLoading(false);
    }
  };
  if (authLoading) return null;
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onFormSubmit(e);
      }}
      className="sign-up-form p-2"
    >
      <h1 className="text-2xl lg:text-3xl font-bold">Join a Company</h1>
      <AuthLabeledTextInput
        disabled={false}
        placeholder="123456"
        name="join_code"
        label="Join Code"
        required
        minLength="8"
        maxLength="8"
        defaultValue={urlJoinCode}
      />
      {firebaseUser?.phoneNumber ? null : <PersonalInfoFields />}
      <p className="my-2 text-sm">
        You can also{" "}
        <Link className="underline cursor-pointer" to="/sign-up">
          create your own company
        </Link>
        .
      </p>

      <SquareButton
        label="Join"
        color="blue"
        loading={isLoading}
        disabled={isLoading}
        className="max-w-none"
        testId="join-form-submit"
      />
    </form>
  );
};

export default JoinForm;
