import React from "react";
import InputWithUnit from "../Inputs/InputWithUnit";

/**
 *
 * @param {{materialsConsumed: Array}} props
 * @returns
 */
const MaterialConsumedTable = ({
  materialsConsumed,
  onMaterialUpdate,
  readOnly,
}) => {
  return (
    <>
      <div className="w-full mb-5">
        <div className="text-sm font-bold mb-0.5 self-start text-snow-primary">
          Materials Consumed
        </div>
        <div className="text-sm font-light mb-1 self-start text-snow-primary">
          {materialsConsumed && materialsConsumed.length > 0
            ? "Materials used while working on this property."
            : "No materials were used during this visit to the property."}
        </div>
        {materialsConsumed ? (
          <table className="w-full">
            <tbody>
              {materialsConsumed.map((m, key) => (
                <tr key={key}>
                  <td>{m.name}</td>
                  <td>
                    {readOnly === true ? (
                      <span>
                        {m.quantity} {m.unit}
                      </span>
                    ) : (
                      <InputWithUnit
                        onChange={(val) => onMaterialUpdate(key, val)}
                        value={m.quantity}
                        unit={m.unit}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
      </div>
    </>
  );
};

export default MaterialConsumedTable;
